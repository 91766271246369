import { isEmpty } from "lodash"
import useSession from "../hooks/useSession"
import { FullPageLoader } from "../components/loader"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import _ from "lodash"

export const WithoutAdmin = ({ children }) => {
  const [user, isLoading] = useSession()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading) {
      if (_.isEmpty(user)) {
        navigate("/login")
      } else {
        if (user?.type === "ADMIN") {
          navigate("/admin")
        }
      }
    }
  }, [user, isLoading])

  if (isLoading) return <FullPageLoader />

  if (isEmpty(user)) {
    window.location.href = "/login"
    return <FullPageLoader />
  }

  if (user?.type === "ADMIN") {
    window.location.href = "/admin/dashboard"
    return <FullPageLoader />
  }

  return <> {children} </>
}
