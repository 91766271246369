import React, { useMemo, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { Box, Button, Item } from "../../components/elements"
import { CardLayout, CardHeader } from "../../components/cards"
import { Breadcrumb, Pagination } from "../../components"
import PageLayout from "../../layouts/PageLayout"
import useSession from "../../hooks/useSession"
import { Skeleton } from "@chakra-ui/react"
import { useQuery } from "react-query"
import { getTradesService } from "../../services/client"
import _ from "lodash"
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "../../components/elements/Table"
import convertDateToSeconds from "../../utils/convertSecondsToDate"
import { LabelField } from "../../components/fields"
import { getAllAccountsService } from "../../services/admin.service"
import DateRangePicker from "../../components/DateRangePicker"
import { formatDateToYYYYMMDD } from "../../utils/formatDate"
import { useEffect } from "react"
import APP from "../../config/app"
import moment from "moment"

export default function AccountReports() {
  const [user] = useSession()

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title="Account Reports">
              <Item className="mc-breadcrumb-item">Home</Item>
              <Item className="mc-breadcrumb-item">Account Reports</Item>
            </Breadcrumb>
          </CardLayout>
        </Col>
      </Row>
      {user ? <TransactionsContainer user={user} /> : null}
    </PageLayout>
  )
}

const TransactionsLoader = () => {
  return <Skeleton minHeight="500px" />
}

const TransactionsContainer = ({ user }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(true)
  const [selectAccount, setSelectedAccount] = useState(null)
  const [forcedSearch, setForcedSearch] = useState(false)
  const [dateRange, setDateRange] = useState([undefined, undefined])
  const [finalState, setFinalState] = useState({
    dateRange,
    selectAccount,
  })

  useEffect(() => {
    setFinalState({
      dateRange,
      selectAccount,
    })
  }, [forcedSearch])

  const handleDownload = () => {
    console.log(dateRange)

    if (!dateRange?.length) return

    let from_date = moment(dateRange[0]).format("YYYY-MM-DD")
    let to_date =
      dateRange?.length == 1
        ? moment(dateRange[1]).add(1, "days").format("YYYY-MM-DD")
        : moment(dateRange[0]).add(1, "days").format("YYYY-MM-DD")

    const url = `${APP.BASE_API_URL}/account-reports?to_date=${to_date}&from_date=${from_date}`

    console.log(url)
    window.open(url, "_blank")
  }

  return (
    <Row>
      <Col xl={12}>
        <CardLayout>
          <Row>
            <Col xl={3}>
              <DateRangePicker
                label="Select Date Range"
                selected={dateRange[0]}
                onChange={setDateRange}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                selectsRange
                //inline
              />
            </Col>

            <Col xl={3}> </Col>
            <Col xl={3}> </Col>

            <Col xl={3}>
              <div style={{ textAlign: "right" }}>
                <br />
                <Button
                  onClick={() => {
                    handleDownload()
                  }}
                  className="mc-btn primary ml-auto"
                >
                  Download
                </Button>
              </div>
            </Col>
          </Row>

          <br />
        </CardLayout>
      </Col>
    </Row>
  )
}

const thead = [
  "Login ID",
  "Volume",
  "Symbol",
  "Open Price",
  "Stop Loss",
  "Take Profit",
  "Position ID",
  "Deal",
  "Order",
  "Action",
  "Entry",
  "Comment",
  "Profit",
  "Time",
]

const MyTradesTable = ({ trades }) => {
  return (
    <>
      <Box className="mc-table-responsive">
        <Table className="mc-table product">
          <Thead className="mc-table-head primary">
            <Tr>
              {thead.map((item, index) => (
                <Th key={index}>{item}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody className="mc-table-body even">
            {trades?.length ? (
              <>
                {_.map(trades, (_r) => {
                  return (
                    <Tr key={_r?.id}>
                      <Td>{_r?.loginId}</Td>

                      <Td>{_r?.volume ? parseInt(_r?.volume) / 10000 : "-"}</Td>
                      <Td>{_r?.symbol}</Td>
                      <Td>{_r?.price}</Td>
                      <Td>{_r?.sl ?? 0}</Td>
                      <Td>{_r?.tp ?? 0}</Td>
                      <Td>{_r?.positionID}</Td>
                      <Td>{_r?.deal}</Td>
                      <Td>{_r?.order_id}</Td>
                      <Td>{_r?.action == 0 ? "Buy" : "Sell"}</Td>
                      <Td>Close</Td>
                      <Td>{_r?.comment}</Td>
                      <Td>{_r?.profit}</Td>
                      <Td>{convertDateToSeconds(_r?.time)}</Td>
                    </Tr>
                  )
                })}
              </>
            ) : (
              <Tr>
                <Td colSpan={thead?.length ?? 1}>
                  <div style={{ textAlign: "center", width: "100%" }}>
                    No Data found
                  </div>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  )
}
