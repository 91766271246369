import { useEffect, useState } from "react"
import { FullPageLoader } from "../components/loader"
import useSession from "../hooks/useSession"
import { useIB, useShowToast } from "../hooks"
import { IB_STATUS_CONS } from "../context/IBContext"
import { Box, Flex, Skeleton, Spinner, useToast } from "@chakra-ui/react"
import _, { isEmpty } from "lodash"
import { createIBRequestService, getMyIBRequestService } from "../services/ib"
import { WithoutAdmin } from "./WithoutAdmin"

export const WithIB = ({ children }) => {
  const [status, isLoading, setStatus] = useIB()

  useEffect(() => {
    if (!isLoading && status === null) {
      fetchIBRequests()
    }
  }, [isLoading, status])

  const fetchIBRequests = async () => {
    try {
      const result = await getMyIBRequestService()

      if (!isEmpty(result?.data?.data?.ib_request?.data)) {
        setStatus(IB_STATUS_CONS.REQUEST_PENDING)
        return
      }
    } catch (error) {}

    setStatus(IB_STATUS_CONS.NOT_IB)
  }

  if (isLoading) return <FullPageLoader />

  // IF NO STATUS
  if (isEmpty(status)) {
    return (
      <>
        <br />
        <Skeleton height="500px" />
      </>
    )
  }

  if (status != IB_STATUS_CONS.IB) {
    return (
      <WithoutAdmin>
        {" "}
        <NotAnIB />{" "}
      </WithoutAdmin>
    )
  }

  return (
    <>
      <WithoutAdmin>{children}</WithoutAdmin>
    </>
  )
}

const NotAnIB = () => {
  const [status, isLoading, setStatus] = useIB()
  const [isCreating, setIsCreating] = useState(false)
  const showToast = useShowToast()

  const handleCreateRequest = async () => {
    setIsCreating(true)

    try {
      const result = await createIBRequestService()

      if (result?.data?.success) {
        setStatus(IB_STATUS_CONS.REQUEST_PENDING)
        showToast("Success", "Request created successfully")
        setIsCreating(false)
        return
      }
    } catch (error) {}

    showToast("Error", "Something went wrong", "error")
    setIsCreating(false)
  }

  return (
    <>
      <br />

      <Flex
        height="500px"
        width="100%"
        align="center"
        justify="center"
        textAlign="center"
      >
        <Box width="100%" maxWidth="400px" mx="auto" textAlign="center">
          <div>
            <i
              style={{ fontSize: "72px", color: "#FF7377" }}
              className="material-icons error"
            >
              error
            </i>
            <br />
            <br />

            {status === IB_STATUS_CONS.REQUEST_PENDING ? (
              <>
                Your existing IB request is pending, one of our team members
                will update it as soon as possible. <br /> Thank you for your
                patience
              </>
            ) : (
              <>
                You're not an IB yet, click the below button to create a
                request, you will be notified via email once approved <br />
                <br />
                <button
                  onClick={handleCreateRequest}
                  className="mc-btn primary"
                >
                  {isCreating ? <Spinner size="xs" /> : "Create Request"}
                </button>
              </>
            )}
          </div>
        </Box>
      </Flex>
    </>
  )
}
