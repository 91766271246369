import React, { useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { Breadcrumb } from "../../components"
import PageLayout from "../../layouts/PageLayout"
import jsonData from "../../data/master/ecommerce.json"
import { Box, Item, Anchor } from "../../components/elements"
import {
  EcommerceCard,
  SalesCard,
  ProductsCard,
  RevenueCard,
  ClientsCard,
  ActivityCard,
  OrdersCard,
} from "../../components/cards"
import { Skeleton } from "@chakra-ui/react"
import useSession from "../../hooks/useSession"
import { useQuery } from "react-query"
import { dashboardService, userDashboardService } from "../../services/client"
import _ from "lodash"
import AIFormModal from "./AIForm"
import {
  CONFIG_KEYS,
  getConfig,
  setConfig,
} from "../../services/config.service"
import { useShowAIForm } from "../../hooks/useShowAIForm"

export default function Ecommerce() {
  const [user, isLoading, setUser] = useSession()

  return (
    <PageLayout>
      <Col xl={12}>
        <Box className="mc-card">
          <Breadcrumb title={jsonData?.pageTitle}>
            {jsonData?.breadcrumb?.map((item, index) => (
              <Item key={index} className="mc-breadcrumb-item">
                {item.path ? (
                  <Anchor className="mc-breadcrumb-link" href={item.path}>
                    {item.text}
                  </Anchor>
                ) : (
                  item.text
                )}
              </Item>
            ))}
          </Breadcrumb>
        </Box>
      </Col>
      {user ? <DashboardContainer user={user} /> : null}
    </PageLayout>
  )
}

const DashboardLoader = () => {
  return (
    <>
      <Row>
        <Col xl={12}>
          <Skeleton minHeight="250px" />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xl={3}>
          <Skeleton minHeight="250px" />
        </Col>
        <Col xl={3}>
          <Skeleton minHeight="250px" />
        </Col>
        <Col xl={3}>
          <Skeleton minHeight="250px" />
        </Col>

        <Col xl={3}>
          <Skeleton minHeight="250px" />
        </Col>
      </Row>

      <br />

      <Row>
        <Col xl={8}>
          <Skeleton minHeight="250px" />
        </Col>
        <Col xl={4}>
          <Skeleton minHeight="250px" />
        </Col>
      </Row>
    </>
  )
}

const DashboardContainer = ({ user }) => {
  const { isLoading, error, data } = useQuery(["clientDashboard"], () =>
    userDashboardService()
  )

  const [openAIModal, setOpenAIModal] = useState(undefined)
  const aiHook = useShowAIForm()

  const accounts = data?.data?.data?._dash?.users?.data ?? []

  useEffect(() => {
    if (aiHook?.isOpen === undefined) {
      let modalShown = getConfig(CONFIG_KEYS.AI_FORM_MODAL_SHOWN)
      if (!modalShown) {
        if (accounts?.length) {
          let neverShownModal = getConfig(
            CONFIG_KEYS.NEVER_SHOW_AI_FORM_MODAL_SHOWN
          )
          if (!neverShownModal) {
            setConfig(CONFIG_KEYS.AI_FORM_MODAL_SHOWN, true)
            aiHook?.onOpen(true)
          }
        }
      }
    }
  }, [accounts, isLoading, aiHook?.isOpen])

  if (isLoading || error) return <DashboardLoader />

  const total = data?.data?.data?.total?.length
    ? data?.data?.data?.total[0]
    : null

  const revenue = data?.data?.data?.revenue?.length
    ? data?.data?.data?.revenue[0]
    : null

  const totalMargin = () => {
    let num = 0

    _.forEach(accounts, (_u) => {
      num += _u?.margin
    })

    return num
  }
  const totalEquity = () => {
    let num = 0

    _.forEach(accounts, (_u) => {
      num += _u?.equity
    })

    return num
  }
  const totalBalance = () => {
    let num = 0

    _.forEach(accounts, (_u) => {
      num += _u?.balance
    })

    return num
  }

  // equity balance used_margin margin
  const getValue = (key) => {
    switch (key) {
      case "equity":
        return totalEquity()

      case "margin":
        return totalMargin()

      case "balance":
        return totalBalance()

      case "accounts":
        return accounts?.length ?? 0

      default:
        return 0
    }
  }

  const handleCloseAIModalForm = () => {
    aiHook?.onClose(false)
  }

  return (
    <>
      <Row>
        <Col xs={12} xl={12}>
          <Row>
            {jsonData?.heros?.map((item, index) => (
              <Col key={index}>
                <EcommerceCard
                  icon={item.icon}
                  trend={item.trend}
                  title={item.title}
                  number={getValue(item?.key).toFixed(2)}
                  variant={item.variant}
                  percent={0}
                  compare={0}
                  dotsMenu={item.dotsMenu}
                />
              </Col>
            ))}

            <Col>
              <EcommerceCard
                icon={"shopping_bag"}
                trend={"trending_up"}
                title={"Wallet Balance"}
                number={data?.data?.data?.walletBalance ?? 0}
                variant={"blue"}
                percent={0}
                compare={0}
                dotsMenu={null}
              />
            </Col>
          </Row>
        </Col>
        {/* <Col xs={12} xl={4}>
          <SalesCard
            title={jsonData?.sales.title}
            amount={getValue("balance")}
            percent={0}
            trendIcon={jsonData?.sales.trendIcon}
            dotsMenu={jsonData?.sales.dotsMenu}
            compare={0}
            chart={jsonData?.sales.chart}
          />
        </Col> */}
        <Col xl={12}></Col>
        <Col xl={8}>
          <RevenueCard
            title={jsonData?.revenue.title}
            field={jsonData?.revenue.field}
            report={jsonData?.revenue.report}
            chart={revenue ?? []}
          />
        </Col>
        <Col xl={4}>
          <OrdersCard
            title={"My Transactions"}
            dotsMenu={jsonData?.orders.dotsMenu}
            items={[
              {
                name: "deposit",
                value: total?.deposit_sum ?? 0,
                color: "purple",
                icon: "pending",
              },
              {
                name: "withdrawal",
                value: total?.withdraw_sum ?? 0,
                color: "blue",
                icon: "add_circle",
              },
            ]}
          />
        </Col>
      </Row>

      <AIFormModal
        isOpen={aiHook?.isOpen}
        onClose={handleCloseAIModalForm}
        name={user?.name ?? ""}
        clientId={user?.id ?? ""}
        accounts={accounts ?? []}
      />
    </>
  )
}
