import React from "react"

export default function Section({ as, children, className, style }) {
  const Component = as || "section"
  return (
    <Component className={className} style={{ ...style }}>
      {children}
    </Component>
  )
}
