import React, { useEffect, useState } from "react"
import {
  Box,
  Form,
  Heading,
  Button,
  Anchor,
  Image,
  Text,
} from "../../components/elements"
import IconField from "../../components/fields/IconField"
import Logo from "../../components/Logo"
import data from "../../data/master/forgot.json"
import useSession from "../../hooks/useSession"
import { Spinner } from "@chakra-ui/react"
import { useShowToast } from "../../hooks/useShowToast"
import { useFormik } from "formik"
import * as Yup from "yup"
import _ from "lodash"
import { sendForgotPasswordEmailService } from "../../services/auth.service"

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required("Email address is required"),
})

export default function ForgotPassword() {
  const [email, setEmail] = useState("")
  const [user] = useSession()

  const [isLoading, setIsLoading] = useState(false)

  const showToast = useShowToast()

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, actions) => {
      try {
        const result = await sendForgotPasswordEmailService(values?.email)

        if (result?.data?.success) {
          showToast("Success", "Reset password instructions sent successfully")
          actions.setSubmitting(false)
          actions.resetForm()
          return
        }
      } catch (error) {}

      showToast("Error", "Something went wrong", "error")
      actions.setSubmitting(false)
      return
    },
  })

  const handleSubmit = () => {
    setIsLoading(true)
    setTimeout(() => {
      showToast("Success", "Reset password email sent successfully")
      setIsLoading(false)
    }, 3000)
  }

  const checkValidEmail = () => {
    let regex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/
    return regex.test(email)
  }

  return (
    <Box
      className="mc-auth"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Image
        className="mc-auth-pattern"
        src={data?.pattern.src}
        alt={data?.pattern.alt}
      />
      <Box className="mc-auth-group" style={{ minWidth: "500px" }}>
        <Logo
          src={data?.logo.src}
          alt={data?.logo.alt}
          href={data?.logo.path}
          className="mc-auth-logo"
        />
        <Heading as="h4" className="mc-auth-title">
          {data?.title}
        </Heading>
        <form onSubmit={formik.handleSubmit}>
          {data?.input.map((item, index) => (
            <IconField
              key={index}
              icon={item.icon}
              type={item.type}
              classes={item.fieldSize}
              placeholder={item.placeholder}
              passwordVisible={item.passwordVisible}
              name="email"
              value={formik?.values.email}
              onBlur={formik?.handleBlur}
              onChange={formik?.handleChange}
              errorMessage={
                formik?.errors?.email && formik?.touched?.email
                  ? formik?.errors?.email
                  : false
              }
            />
          ))}
          <Button
            disabled={formik?.isSubmitting}
            isLoading={formik?.isSubmitting}
            className={`mc-auth-btn ${data?.button.fieldSize}`}
            type="submit"
            onClick={handleSubmit}
          >
            {isLoading ? <Spinner /> : data?.button.text}
          </Button>
        </form>

        <Box className="mc-auth-navigate">
          {/* <Text as="span">{data?.navigate.title}</Text> */}
          <Anchor href={user ? "/dashboard" : data?.navigate.path}>
            {user ? "Dashboard" : data?.navigate.text}
          </Anchor>
        </Box>
      </Box>
    </Box>
  )
}
