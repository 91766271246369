import { Grid, Spinner } from "@chakra-ui/react"
import { useFormik, getIn } from "formik"
import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import _ from "lodash"
import { LabelField } from "../../../components/fields"
import { Box, Icon, Label } from "../../../components/elements"

import {
  addNewUser,
  adminCreateTradingAccountService,
  changeUserActivePlanService,
} from "../../../services/admin.service"
import { useShowToast } from "../../../hooks"
import CustomReactSelect from "../../../components/fields/CustomReactSelect"

const thead = ["Level", "Commission Per Lot", ""]
const SignupSchema = Yup.object().shape({
  user: Yup.number().required("Required"),
  plan: Yup.number().required("Required"),
  type: Yup.number().required("Required"),
})
const ChangeIbPlanForm = ({ users, plans }) => {
  const showToast = useShowToast()

  const formik = useFormik({
    initialValues: {
      user: undefined,
      plan: undefined,
      type: 1,
    },
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true)

      try {
        const p = _.find(plans, (_p) =>
          _p.id === parseInt(values?.plan) ? true : false
        )

        const result = await adminCreateTradingAccountService(
          parseInt(values?.user),
          p?.id,
          parseInt(values?.type) === 1 ? p?._Group : p?.demo_group,
          parseInt(values?.type)
        )

        console.log("RESUKT", result)

        if (result?.data?.success) {
          showToast(
            "Success",
            "MT5 Account created successfully " +
              result?.data?.data?.accounts?.account_id
          )
          actions.setSubmitting(false)
          actions.resetForm()
          return
        }
      } catch (e) {
        console.log(e)
      }

      showToast("Error", "Something went wrong", "error")
      actions.setSubmitting(false)
    },
    validationSchema: SignupSchema,
  })

  const getUserValue = () => {
    if (formik?.values.user) {
      const _u = _.find(users, (u) =>
        u.id === formik?.values.user ? true : false
      )

      if (_u) {
        return {
          label: _u?.id + "  " + _u?.name + "  " + _u?.email,
          value: _u?.id,
        }
      }
    }
    return {
      label: "Select User",
      value: undefined,
    }
  }

  const getActivePlan = () => {
    const _p = _.find(plans, (p) => {
      if (p?.id === parseInt(formik.values.plan)) return true

      return false
    })

    if (_p) {
      return {
        label: _p?.title + "  (Leverage : " + _p?.Leverage + ")",
        value: _p?.id,
      }
    }

    return undefined
  }

  return (
    <>
      <br />

      <form onSubmit={formik.handleSubmit}>
        <Grid gap={4} my={4} maxWidth="500px">
          <Box>
            <CustomReactSelect
              label="User"
              options={[
                {
                  label: "Select User",
                  value: undefined,
                },
                ..._.map(_.sortBy(users, "id"), (_u) => {
                  return {
                    label: _u?.id + "  " + _u?.name + "  " + _u?.email,
                    value: _u?.id,
                  }
                }),
              ]}
              value={getUserValue()}
              errorMessage={
                formik?.errors?.user && formik?.touched?.user
                  ? formik?.errors?.user
                  : false
              }
              onChange={(e) => {
                formik.setFieldTouched("user")
                formik.setFieldValue("user", e?.value)
              }}
              onBlur={() => {
                formik.setFieldTouched("user")
              }}
            />
          </Box>

          <Box>
            <CustomReactSelect
              label="Plan"
              name="plan"
              options={[
                {
                  label: "Select Plan",
                  value: undefined,
                },
                ..._.map(_.sortBy(plans, "id"), (_u) => {
                  return {
                    label: _u?.title + "  (Leverage : " + _u?.Leverage + ")",
                    value: _u?.id,
                  }
                }),
              ]}
              value={getActivePlan()}
              errorMessage={
                formik?.errors?.plan && formik?.touched?.plan
                  ? formik?.errors?.plan
                  : false
              }
              onChange={(e) => {
                formik.setFieldTouched("plan")
                formik.setFieldValue("plan", e?.value)
              }}
              onBlur={() => {
                formik.setFieldTouched("plan")
              }}
            />
          </Box>

          <LabelField
            label="Type"
            name="type"
            option={[
              { title: "Live Account", value: 1 },
              { title: "Demo Account", value: 0 },
            ]}
            value={formik?.values?.type}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            errorMessage={
              formik?.errors?.type && formik?.touched?.type
                ? formik?.errors?.type
                : false
            }
          />

          <Box style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ marginLeft: "auto" }}></div>

            <button
              type="submit"
              className="mc-btn primary"
              style={{ minHeight: "42px" }}
            >
              {formik?.isSubmitting ? <Spinner /> : "Create "}
            </button>
          </Box>
        </Grid>
      </form>
    </>
  )
}

export default ChangeIbPlanForm
