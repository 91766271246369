import React, { useState, useEffect } from "react"
import Modal from "react-bootstrap/Modal"
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table"
import {
  Anchor,
  Heading,
  Box,
  Text,
  Input,
  Image,
  Icon,
  Button,
} from "../elements"
import { map } from "lodash"
import moment from "moment"
import { Badge, Grid } from "@chakra-ui/react"

export default function ProductsTable(props) {
  const { thead, tbody, data, onChangeLeverage, onChangePassword } = props

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table product">
        <Thead className="mc-table-head primary">
          <Tr>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {map(data, (item, index) => (
            <Tr key={item?.id}>
              <Td>
                {item?.account_id}
                <br />
                {item?.is_ib ? "(IB Account)" : ""}
              </Td>
              <Td>{item.platform}</Td>

              <Td>
                <Text style={{ textAlign: "right" }}>
                  {item._leverage ?? "-"}
                </Text>
              </Td>

              <Td>
                {moment(item.created_at).format("Do MMM YYYY, h:mm:ss a")}
              </Td>
              <Td>
                {!item._leverage ? (
                  <>
                    <Badge
                      display="inline-block"
                      variant="solid"
                      colorScheme="gray"
                    >
                      DISABLED
                    </Badge>
                  </>
                ) : (
                  <Badge
                    display="inline-block"
                    variant="solid"
                    colorScheme="green"
                  >
                    {item.status}
                  </Badge>
                )}
              </Td>

              <Td>
                {item._leverage ? (
                  <Grid gap={2} maxWidth="120px">
                    <button
                      type="button"
                      className="mc-btn primary"
                      style={{ display: "inline-block" }}
                      onClick={() => {
                        if (onChangeLeverage) {
                          onChangeLeverage(item)
                        }
                      }}
                    >
                      Change Leverage
                    </button>{" "}
                    <button
                      type="button"
                      className="mc-btn primary"
                      onClick={() => {
                        if (onChangePassword) {
                          onChangePassword(item)
                        }
                      }}
                    >
                      Change Password
                    </button>{" "}
                  </Grid>
                ) : (
                  <div></div>
                )}
              </Td>
            </Tr>
          ))}

          {!data?.length ? (
            <Tr>
              <Td colSpan={thead?.length}>
                <div style={{ textAlign: "center", width: "100%" }}>
                  No Accounts present
                </div>
              </Td>
            </Tr>
          ) : null}
        </Tbody>
      </Table>
    </Box>
  )
}
