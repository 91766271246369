import React from "react"
import { Box, Input, Label, Select, Option } from "../elements"

export default function LabelField({
  label,
  labelDir,
  fieldSize,
  option,
  type,
  placeholder,
  errorMessage,
  optionStyles,
  ...rest
}) {
  return (
    <>
      <Box
        className={`mc-label-field-group ${
          label ? labelDir || "label-col" : ""
        }`}
        style={{ width: "100%" }}
      >
        {label && <Label className="mc-label-field-title">{label}</Label>}
        {type ? (
          <Input
            type={type || "text"}
            placeholder={placeholder || ""}
            className={`mc-label-field-input ${fieldSize || "w-md h-sm"}`}
            {...rest}
          />
        ) : (
          <Select
            className={`mc-label-field-select ${fieldSize || "w-md h-sm"}`}
            optionStyles={optionStyles}
            {...rest}
          >
            {option.map((item, index) => (
              <Option
                key={item?.value}
                value={item?.value}
                optionStyles={optionStyles}
              >
                {item?.title}
              </Option>
            ))}
          </Select>
        )}
      </Box>
      {errorMessage ? (
        <div
          className="text-danger"
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: "14px",

            marginBottom: "12px",
          }}
        >
          {errorMessage}
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export const LabelFieldWrapper = ({
  label,
  labelDir,
  fieldSize,
  option,
  type,
  placeholder,
  errorMessage,
  optionStyles,
  children,
  ...rest
}) => {
  return (
    <>
      <Box
        className={`mc-label-field-group ${
          label ? labelDir || "label-col" : ""
        }`}
      >
        {label && <Label className="mc-label-field-title">{label}</Label>}
        {children}
      </Box>
      {errorMessage ? (
        <div
          className="text-danger"
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: "14px",

            marginBottom: "12px",
          }}
        >
          {errorMessage}
        </div>
      ) : (
        ""
      )}
    </>
  )
}
