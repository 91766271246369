import { Flex, Grid, Radio, RadioGroup, Spinner } from "@chakra-ui/react"
import { useFormik, getIn } from "formik"
import React, { useEffect, useMemo, useState } from "react"
import * as Yup from "yup"
import _ from "lodash"
import { LabelField } from "../../../../components/fields"
import { Box, Icon, Label } from "../../../../components/elements"

import {
  addNewUser,
  adminCreateDepositWithdrawalRequest,
  adminTransferCreditService,
  getUserBalanceService,
} from "../../../../services/admin.service"
import { useShowToast } from "../../../../hooks"
import CustomReactSelect from "../../../../components/fields/CustomReactSelect"
import { TransactionFlowEnum } from "../../../../config/enum"
import { useQuery } from "react-query"

const thead = ["Level", "Commission Per Lot", ""]
const SignupSchema = Yup.object().shape({
  amount: Yup.number()
    .typeError("Required")
    .required("Required")
    .min(1, "Must be greater than 0"),
  comments: Yup.string(),
})

// 'DEPOSIT','WITHDRAW','TRANSFER','CREDITIN','CREDITOUT'
const typeOptions = [
  {
    value: "DEPOSIT",
    title: "DEPOSIT",
  },
  {
    value: "WITHDRAW",
    title: "WITHDRAW",
  },
]

// accounts methods
const TransferCreditsForm = ({ accounts, methods }) => {
  const showToast = useShowToast()

  const allUsers = useMemo(() => {
    let _users = _.uniqBy(accounts, "user_id")

    _users = _.sortBy(_users, "name")

    return _users
  }, [accounts])

  const formik = useFormik({
    initialValues: {
      transaction_flow: TransactionFlowEnum.DIRECT_WALLET,
      id: undefined,
      user_id: undefined,
      account_id: 1,
      amount: 0,
      comment: "",
      type: "DEPOSIT",
    },
    onSubmit: async (values, actions) => {
      console.log(values)

      if (values?.transaction_flow === TransactionFlowEnum.DIRECT_WALLET) {
        if (!values?.user_id) {
          actions.setFieldTouched("user_id", true)
          actions.setFieldError("user_id", "Please select a user")
          actions.setFieldTouched("id", true)
          actions.setFieldError("id", "Please select a account")
          actions.setSubmitting(false)
          return
        }
      } else {
        if (!values?.id) {
          actions.setFieldTouched("user_id", true)
          actions.setFieldError("user_id", "Please select a user")
          actions.setFieldTouched("id", true)
          actions.setFieldError("id", "Please select a account")
          actions.setSubmitting(false)

          return
        }
      }

      console.log(values)

      actions.setSubmitting(true)

      try {
        const result = await adminCreateDepositWithdrawalRequest({
          amount: values?.amount,
          type:
            values?.transaction_flow === TransactionFlowEnum.DIRECT_WALLET
              ? values?.type
              : values.type === "DEPOSIT"
              ? "WITHDRAW"
              : "DEPOSIT",
          comment: values?.comment,
          transaction_flow: values?.transaction_flow,
          user_id: values?.user_id,
          account_id: 1,
          id: values?.id ?? null,
        })

        if (result?.data?.success) {
          showToast("Success", "Transaction created successfully")
          actions.setSubmitting(false)
          actions.resetForm()
          return
        }
      } catch (e) {
        console.log(e)
      }

      showToast("Error", "Something went wrong", "error")
      actions.setSubmitting(false)
    },
    validationSchema: SignupSchema,
  })

  const getBalanceQuery = useQuery({
    queryKey: ["userbalance", formik?.values?.user_id],
    queryFn: () => getUserBalanceService(formik?.values?.user_id),
    // ⬇️ disabled as long as the filter is empty
    enabled: !!formik?.values?.user_id,
  })

  const getActiveAccount = () => {
    try {
      const acc = _.find(accounts, (a) =>
        a?.id === formik?.values?.id ? true : false
      )

      if (acc) {
        return {
          label:
            acc?.account_id +
            "  " +
            acc?.name +
            (acc?.is_live ? " (LIVE)" : " (DEMO)"),
          value: acc?.id,
        }
      }
    } catch (error) {}

    return {
      label: "Select Account",
      value: undefined,
    }
  }

  const getActiveUser = () => {
    try {
      const u = _.find(allUsers, (_u) =>
        _u?.user_id === formik?.values?.user_id ? true : false
      )

      if (u) {
        return {
          label: u?.name + "(" + u?.email + ")",
          value: u?.user_id,
        }
      }
    } catch (error) {}

    return {
      label: "Select User",
      value: undefined,
    }
  }

  useEffect(() => {
    if (!formik?.values?.id) {
      formik.setFieldValue("user_id", undefined)
      return
    }

    const acc = _.find(accounts, (_acc) => _acc?.id === formik?.values?.id)

    if (acc) {
      console.log(acc)
      formik.setFieldValue("user_id", acc?.user_id)
    }
  }, [formik?.values?.id])

  useEffect(() => {
    formik.setFieldValue("user_id", undefined)
    formik.setFieldValue("id", undefined)
  }, [formik?.values?.transaction_flow])

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid gap={4} my={4} maxWidth="500px">
          <Box>
            <Box>
              <Label className="mc-label-field-title">Type</Label>
              <RadioGroup
                gap={4}
                name="transaction_flow"
                value={formik.values.transaction_flow}
                onChange={(_val) => {
                  formik.setFieldValue("transaction_flow", _val)
                }}
              >
                <Radio value={TransactionFlowEnum.DIRECT_WALLET}>
                  Direct Wallet
                </Radio>{" "}
                &emsp;
                <Radio value={TransactionFlowEnum.WALLET_ACCOUNT}>
                  Wallet to Account
                </Radio>
              </RadioGroup>
            </Box>
          </Box>

          <Box>
            <Box
              style={{
                display:
                  formik.values.transaction_flow ===
                  TransactionFlowEnum.DIRECT_WALLET
                    ? "block"
                    : "none",
              }}
            >
              <CustomReactSelect
                label="Select User"
                name="user_id"
                options={[
                  {
                    label: "Select User",
                    value: undefined,
                  },
                  ..._.map(allUsers, (_u) => {
                    return {
                      label: _u?.name + "(" + _u?.email + ")",
                      value: _u?.user_id,
                    }
                  }),
                ]}
                value={getActiveUser()}
                errorMessage={
                  formik?.errors?.user_id ? formik?.errors?.user_id : false
                }
                onChange={(e) => {
                  formik.setFieldTouched("user_id")
                  formik.setFieldValue("user_id", e?.value)
                }}
                onBlur={() => {
                  formik.setFieldTouched("user_id")
                }}
              />
            </Box>

            <Box
              style={{
                display:
                  formik.values.transaction_flow ===
                  TransactionFlowEnum.WALLET_ACCOUNT
                    ? "block"
                    : "none",
              }}
            >
              <CustomReactSelect
                label="Select Account"
                name="id"
                options={[
                  {
                    label: "Select Account",
                    value: undefined,
                  },
                  ..._.map(_.sortBy(accounts, "account_id"), (_u) => {
                    return {
                      label:
                        _u?.account_id +
                        "  " +
                        _u?.name +
                        (_u?.is_live ? " (LIVE)" : " (DEMO)"),
                      value: _u?.id,
                    }
                  }),
                ]}
                value={getActiveAccount()}
                errorMessage={formik?.errors?.id ? formik?.errors?.id : false}
                onChange={(e) => {
                  formik.setFieldTouched("id")
                  formik.setFieldValue("id", e?.value)
                }}
                onBlur={() => {
                  formik.setFieldTouched("id")
                }}
              />
            </Box>
          </Box>

          <LabelField
            label="Select Type"
            name="type"
            option={typeOptions}
            value={formik?.values?.type}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            errorMessage={
              formik?.errors?.type && formik?.touched?.type
                ? formik?.errors?.type
                : false
            }
          />

          <Box>
            <LabelField
              label="Amount"
              type="number"
              name="amount"
              value={formik?.values?.amount}
              onBlur={formik?.handleBlur}
              onChange={formik?.handleChange}
              errorMessage={
                formik?.errors?.amount && formik?.touched?.amount
                  ? formik?.errors?.amount
                  : false
              }
            />
            <div
              style={{ fontSize: "12px", marginTop: "8px", marginLeft: "8px" }}
            >
              {formik?.values?.user_id
                ? getBalanceQuery?.isLoading
                  ? "Fetching balance..."
                  : `Current Wallet Balance :
                      ${
                        getBalanceQuery?.data?.data?.data?.walletBalance != null
                          ? getBalanceQuery?.data?.data?.data?.walletBalance
                          : 0
                      }`
                : ""}
            </div>
          </Box>

          <LabelField
            label="Comment"
            type="text"
            name="comment"
            value={formik?.values?.comment}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            errorMessage={
              formik?.errors?.comment && formik?.touched?.comment
                ? formik?.errors?.comment
                : false
            }
          />

          <Box style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ marginLeft: "auto" }}></div>

            <button
              type="submit"
              className="mc-btn primary"
              style={{ minHeight: "42px" }}
            >
              {formik?.isSubmitting ? <Spinner /> : "Transfer Balance"}
            </button>
          </Box>
        </Grid>
      </form>
    </>
  )
}

export default TransferCreditsForm
