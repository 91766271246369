import React, { useState, useEffect } from "react"
import { Modal, Form } from "react-bootstrap"
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table"
import {
  Button,
  Image,
  Input,
  Text,
  Box,
  Icon,
  Anchor,
  Option,
  Heading,
} from "../elements"
import userInfo from "../../data/master/userList.json"
import _ from "lodash"
import { Avatar } from "@chakra-ui/react"

export default function DepositTable({
  thead,
  tbody,
  type,
  onSelectMedium,
  data,
}) {
  const [userData, setUserData] = React.useState("")
  const [editModal, setEditModal] = React.useState(false)
  const [blockModal, setBlockModal] = React.useState(false)

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {_.map(data, (item, index) => {
            console.log(item)

            return (
              <Tr key={index}>
                <Td title={item.title}>
                  <Avatar
                    src={`/images/${item.title?.split(" ").join("")}.png`}
                    name={item?.title}
                  />
                </Td>

                <Td title={item.title}>{item.title}</Td>
                <Td title={item.currency}>{item.currency}</Td>
                <Td title={item.fee_commission}>{item.fee_commission}</Td>
                <Td title={item.type}>{item.type}</Td>
                <Td title={item.processing_time}>{item.processing_time}</Td>
                <Td title={item.time}>
                  <Button
                    onClick={() => {
                      onSelectMedium(item)
                    }}
                    className="mc-btn primary sm"
                  >
                    {type}
                  </Button>
                </Td>
              </Tr>
            )
          })}

          {/* {data?.map((item, index) => (

          ))} */}
        </Tbody>
      </Table>

      <Modal
        show={editModal}
        onHide={() => setEditModal(false, setUserData(""))}
      >
        <Box className="mc-user-modal">
          <Image src={userData.src} alt={userData?.alt} />
          <Heading as="h4">{userData?.name}</Heading>
          <Text as="p">{userData?.email}</Text>
          <Form.Group className="form-group inline mb-4">
            <Form.Label>role</Form.Label>
            <Form.Select>
              <Option>{userData?.role ? userData?.role.text : ""}</Option>
              {userInfo.role.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="form-group inline">
            <Form.Label>status</Form.Label>
            <Form.Select>
              <Option>{userData?.status}</Option>
              {userInfo.status.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Form.Select>
          </Form.Group>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => setEditModal(false)}
            >
              close popup
            </Button>
            <Button
              type="button"
              className="btn btn-success"
              onClick={() => setEditModal(false)}
            >
              save Changes
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>

      <Modal show={blockModal} onHide={() => setBlockModal(false)}>
        <Box className="mc-alert-modal">
          <Icon type="new_releases" />
          <Heading as="h3">are your sure!</Heading>
          <Text as="p">Want to block this user's account?</Text>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => setBlockModal(false)}
            >
              nop, close
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => setBlockModal(false)}
            >
              yes, block
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>
    </Box>
  )
}
