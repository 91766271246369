import { useToast } from "@chakra-ui/react"

export const useShowToast = () => {
  const toast = useToast()

  const showToast = (
    title,
    message,
    type = "success" | "error" | "warning"
  ) => {
    toast({
      title: title,
      description: message,
      status: type ? type : "success",
      duration: 4000,
      isClosable: true,
      positon: "top-right",
    })
  }

  return showToast
}
