import React, { useEffect, useState } from "react"
import PageLayout from "../../../layouts/PageLayout"
import { Col, Row } from "react-bootstrap"
import { CardLayout } from "../../../components/cards"
import { Anchor, Button, Item, Text } from "../../../components/elements"
import { Breadcrumb, Pagination } from "../../../components"
import { Box, Flex } from "@chakra-ui/layout"
import { LabelField, LabelTextarea } from "../../../components/fields"
import { useFormik } from "formik"
import { createSupportTicket, getSupportTickets } from "../../../services/ib"
import * as Yup from "yup"
import { useShowToast } from "../../../hooks"
import { Center, Text as TruncateText } from "@chakra-ui/react"
import * as _ from "lodash"
import { Spinner } from "@chakra-ui/react"
import { usePriority, useStatus } from "../../../utils/useHook"
import ReactPaginate from "react-paginate"

const Support = () => {
  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title="Support">
              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href="/">
                  Home
                </Anchor>
              </Item>
              <Item className="mc-breadcrumb-item">Support</Item>
            </Breadcrumb>
          </CardLayout>
        </Col>
      </Row>

      <SupportContainer />
    </PageLayout>
  )
}

const sortParams = [
  { key: "date_created", value: "Date Created" },
  { key: "date_modified", value: "Date Modified" },
]

const SupportContainer = () => {
  const [openForm, setOpenForm] = useState(false)
  // const [sortOrder, setSortOrder] = useState(sortParams[0]?.key);
  const [tickets, setTickets] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)

  const [lastPage, setLastPage] = useState(1)
  // fetch support tickets
  useEffect(() => {
    async function fetchTickets() {
      setIsFetching(true)
      const result = await getSupportTickets(undefined, currentPage)

      if (result?.data?.success) {
        const data = result?.data?.data?.support?.data

        if (result?.data?.data?.support?.last_page) {
          setLastPage(result?.data?.data?.support?.last_page)
        }

        setTickets(data)
      } else {
        console.log("failed to fetch!")
      }
      setIsFetching(false)
    }

    fetchTickets()
  }, [currentPage])

  return (
    <Row>
      <Col xl={12}>
        <CardLayout>
          <Flex
            alignItems={"center"}
            marginBottom={"20px"}
            justifyContent={"space-between"}
          >
            <Box>
              {/* <CardHeader
                title={`${openForm ? "Create new ticket" : "Open or Pending"}`}
                style={{ margin: 0, marginBottom: "-5px" }}
              />
              {!openForm && (
                <Text as="small">
                  Sorted by
                  <select
                    style={{ fontWeight: "bold" }}
                    value={sortOrder}
                    onChange={(e) => setSortOrder(e.target.value)}
                  >
                    {sortParams?.map((item, index) => (
                      <option key={item.key} value={item.key}>
                        {item.value}
                      </option>
                    ))}
                  </select>
                </Text>
              )} */}
            </Box>
          </Flex>
          {openForm ? (
            <CreateNewTicket
              setOpenForm={setOpenForm}
              setTickets={setTickets}
            />
          ) : (
            <>
              {isFetching ? (
                <Center marginTop="20px">
                  <Spinner size="xl" />
                </Center>
              ) : !tickets.length ? (
                <Text>There are no available tickets!</Text>
              ) : (
                tickets?.map((item, index) => (
                  <CardBox key={index} data={item} />
                ))
              )}
            </>
          )}

          <br />
          <div
            style={{
              textAlign: "right",
              display: "flex",
            }}
          >
            <div style={{ flex: 1 }}></div>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={(e) => {
                setCurrentPage(e?.selected + 1)
              }}
              pageRangeDisplayed={5}
              pageCount={lastPage}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
            />
          </div>
        </CardLayout>
      </Col>
    </Row>
  )
}

const CardBox = ({ data }) => {
  const [dateString, setDateString] = useState("")
  const status = useStatus(data?.status)
  const priority = usePriority(data?.priority)

  useEffect(() => {
    const createdDate = new Date(data.created_at)
    const dateString = createdDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
    setDateString(dateString)
  }, [])

  return (
    <Box
      mb={2}
      p={4}
      border="1px solid"
      borderColor="lightgray"
      borderRadius="5px"
      _hover={{ cursor: "pointer", backgroundColor: "#f1f0f0" }}
      onClick={() => (window.location.href = `/admin/support/${data.id}`)}
    >
      <Flex alignItems="center" gap={8}>
        <div
          style={{
            minWidth: "12px",
            minHeight: "12px",
            backgroundColor: status.color,
            borderRadius: "50%",
          }}
        ></div>
        <Flex flexDirection="column" style={{ minWidth: "100px" }}>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: "14px",
              textWrap: "nowrap",
              color: status.color,
            }}
          >
            {status.value}
          </Text>
          <Text as="small" style={{ color: "gray" }}>
            {dateString}
          </Text>{" "}
        </Flex>
        <Box style={{ flex: 1 }}>
          <TruncateText as={"b"} color="#2E2E2E">
            {data.title}
          </TruncateText>
          <TruncateText as="small" noOfLines={1}>
            {data.support_text}
          </TruncateText>
        </Box>

        <div>
          <Text as="small" style={{ color: "gray" }}>
            by {data?.name}
          </Text>{" "}
        </div>
        <div style={{ width: "100px" }}>
          <div>
            <Box
              style={{
                padding: "1px 10px",
                border: `2px solid ${priority.color}`,
                borderRadius: "10px",
                background: priority.color,
                color: "white",
                fontWeight: "500",
                textAlign: "center",
                textTransform: "uppercase",
                fontSize: "12px",
              }}
            >
              {priority.value}
            </Box>
          </div>
        </div>
      </Flex>
    </Box>
  )
}

const ticketSchema = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  description: Yup.string().required("Description is required!"),
  priority: Yup.string().required("Please select a priority!"),
})

const CreateNewTicket = ({ setOpenForm, setTickets }) => {
  const showToast = useShowToast()

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      priority: "",
    },
    enableReinitialize: true,
    validationSchema: ticketSchema,
    onSubmit: (values, actions) => {
      handleTicket(values, actions)
    },
  })

  const handleTicket = async (values, actions) => {
    actions.setSubmitting(true)

    try {
      const result = await createSupportTicket(
        values?.title,
        values?.description,
        values?.priority
      )

      if (result?.data?.success) {
        setTickets((data) => [result?.data?.data?.support, ...data])
        showToast("Success", "Deposit request created successfully")
        actions?.resetForm()
        actions.setSubmitting(false)
        setOpenForm(false)
        return
      }
    } catch (error) {}

    showToast("Error", "Something went wrong", "error")
    actions.setSubmitting(false)
  }

  return (
    <>
      <Row>
        <form onSubmit={formik.handleSubmit}>
          <LabelField
            label="Title"
            type="text"
            fieldSize="w-100 h-md"
            name={"title"}
            value={formik?.values.title}
            onChange={formik?.handleChange}
            errorMessage={
              formik?.errors?.title && formik?.touched?.title
                ? formik?.errors?.title
                : false
            }
          />
          <br />
          <LabelField
            label="Priority"
            fieldSize="w-100 h-md"
            name={"priority"}
            option={[
              {
                title: "Select the priority!",
                value: "",
              },
              {
                title: "Low",
                value: "low",
              },
              {
                title: "Medium",
                value: "medium",
              },
              {
                title: "High",
                value: "high",
              },
            ]}
            value={formik?.values.priority}
            onChange={formik?.handleChange}
            errorMessage={
              formik?.errors?.priority && formik?.touched?.priority
                ? formik?.errors?.priority
                : false
            }
          />
          <br />
          <LabelTextarea
            label="Description"
            fieldSize="w-100"
            name={"description"}
            value={formik?.values.description}
            onChange={formik?.handleChange}
            errorMessage={
              formik?.errors?.description && formik?.touched?.description
                ? formik?.errors?.description
                : false
            }
          />
          <br />
          {/* <LabelField
            label="Attach a file"
            type="file"
            fieldSize="w-100 h-md"
            accept=".jpg, .jpeg, .png, .gif, .pdf"
            ref={imageUploadref}
            onChange={(e) => {
              if (e?.target?.files?.length) {
                setScreenShot(e?.target?.files[0]);
              } else {
                setScreenShot(null);
              }
            }}
          /> */}

          <Flex gap={5} marginTop={10}>
            <Button
              className="mc-btn red h-sm"
              onClick={() => setOpenForm(false)}
              disabled={formik?.isSubmitting}
              style={{
                filter: `contrast(${formik?.isSubmitting ? "50%" : 1})`,
              }}
            >
              Cancel
            </Button>

            <button
              type="button"
              className="mc-btn primary h-sm"
              disabled={formik?.isSubmitting}
              onClick={(e) => {
                e.preventDefault()
                formik.submitForm()
              }}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div>
                {formik?.isSubmitting ? <Spinner size="xs" /> : "Submit"}
              </div>
            </button>
          </Flex>
        </form>
      </Row>
    </>
  )
}

export default Support
