import React, { useMemo, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { Box, Button, Item } from "../../components/elements"
import { CardLayout, CardHeader } from "../../components/cards"
import { Breadcrumb, Pagination } from "../../components"
import PageLayout from "../../layouts/PageLayout"
import useSession from "../../hooks/useSession"
import { Skeleton } from "@chakra-ui/react"
import { useQuery } from "react-query"
import { getTradesService } from "../../services/client"
import _ from "lodash"
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "../../components/elements/Table"
import convertDateToSeconds from "../../utils/convertSecondsToDate"
import { LabelField } from "../../components/fields"
import { getAllAccountsService } from "../../services/admin.service"
import DateRangePicker from "../../components/DateRangePicker"
import { formatDateToYYYYMMDD } from "../../utils/formatDate"
import { useEffect } from "react"
import downloadFile from "../../utils/downloadFile"
import convertJsonToCsv from "../../utils/convertJsonToCsv"

export default function IBTrades() {
  const [user] = useSession()

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title="Trades">
              <Item className="mc-breadcrumb-item">Home</Item>
              <Item className="mc-breadcrumb-item">Trades</Item>
            </Breadcrumb>
          </CardLayout>
        </Col>
      </Row>
      {user ? <TransactionsContainer user={user} /> : null}
    </PageLayout>
  )
}

const TransactionsLoader = () => {
  return <Skeleton minHeight="500px" />
}

const TransactionsContainer = ({ user }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(true)
  const [selectAccount, setSelectedAccount] = useState(null)
  const [forcedSearch, setForcedSearch] = useState(false)
  const [dateRange, setDateRange] = useState([undefined, undefined])
  const [finalState, setFinalState] = useState({
    dateRange,
    selectAccount,
  })
  const [isDownloading, setIsDownloading] = useState(false)

  const { isLoading, error, data } = useQuery(
    ["ib-trades", currentPage, forcedSearch, finalState],
    () =>
      getTradesService(
        currentPage,
        null,
        finalState.selectAccount,
        finalState?.dateRange?.length && finalState?.dateRange[0] > 0
          ? formatDateToYYYYMMDD(finalState.dateRange[0])
          : undefined,
        finalState?.dateRange?.length > 1
          ? formatDateToYYYYMMDD(finalState.dateRange[1])
          : undefined
      ),
    {
      refetchInterval: 4500,
    }
  )

  useEffect(() => {
    setFinalState({
      dateRange,
      selectAccount,
    })
  }, [forcedSearch])

  const handleCSVDownload = async () => {
    setIsDownloading(true)

    try {
      const result = await getTradesService(
        currentPage,
        null,
        finalState.selectAccount,
        finalState?.dateRange?.length && finalState?.dateRange[0] > 0
          ? formatDateToYYYYMMDD(finalState.dateRange[0])
          : undefined,
        finalState?.dateRange?.length > 1
          ? formatDateToYYYYMMDD(finalState.dateRange[1])
          : undefined,
        true
      )
      console.log(result?.data?.data?.trades)

      const formattedData = _.map(result?.data?.trades, (_r) => {
        const _request = _r[0]

        return {
          Login: _r?.loginId,
          Volume: _r?.volume ? parseInt(_r?.volume) / 10000 : "-",
          Symbol: _r?.symbol,
          OpenPrice: _r?.price,
          StopLoss: _r?.sl ?? 0,
          TakeProfit: _r?.tp ?? 0,
          PositionID: _r?.positionID,
          Deal: _r?.deal,
          Order: _r?.order_id,
          Action: _r?.action == 0 ? "Buy" : "Sell",
          Comment: _r?.comment,
          Profit: _r?.profit,
          Time: convertDateToSeconds(_r?.time),
        }
      })

      //      console.log()
      downloadFile({
        data: convertJsonToCsv(formattedData, true),
        fileName: "trades.csv",
        fileType: "text/csv",
      })
    } catch (error) {
      console.error("Error downloading file:", error)
    }

    setIsDownloading(false)
  }

  if (isLoading) return <TransactionsLoader />

  return (
    <Row>
      <Col xl={12}>
        <CardLayout>
          <CardHeader title="Trades" />
          <br />

          <Row>
            <Col xl={3}>
              <DateRangePicker
                label="Select Date Range"
                selected={dateRange[0]}
                onChange={setDateRange}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                selectsRange
                //inline
              />
            </Col>

            <Col xl={3}>
              {window.location.href?.includes("admin") ? (
                <LabelField
                  type="text"
                  label="Select Account"
                  value={selectAccount}
                  onChange={(e) => {
                    setSelectedAccount(e?.target?.value)
                  }}
                />
              ) : null}
            </Col>

            <Col xl={3}> </Col>

            <Col xl={3}>
              <div style={{ textAlign: "right" }}>
                <br />
                <Button
                  onClick={() => {
                    handleCSVDownload()
                  }}
                  className="mc-btn green"
                >
                  {isDownloading ? "Downloading" : "Download"}
                </Button>
                &emsp;
                <Button
                  onClick={() => {
                    setCurrentPage(1)
                    setForcedSearch((v) => !v)
                  }}
                  className="mc-btn primary ml-auto"
                >
                  Search
                </Button>
              </div>
            </Col>
          </Row>

          <br />

          <MyTradesTable trades={data?.data?.data?.trades[0]?.data ?? []} />
          <br />
          <Pagination
            lastPage={data?.data?.data?.trades[0]?.lastPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </CardLayout>
      </Col>
    </Row>
  )
}

const thead = [
  "Login ID",
  "Volume",
  "Symbol",
  "Open Price",
  "Stop Loss",
  "Take Profit",
  "Position ID",
  "Deal",
  "Order",
  "Action",
  "Entry",
  "Comment",
  "Profit",
  "Time",
]

const MyTradesTable = ({ trades }) => {
  return (
    <>
      <Box className="mc-table-responsive">
        <Table className="mc-table product">
          <Thead className="mc-table-head primary">
            <Tr>
              {thead.map((item, index) => (
                <Th key={index}>{item}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody className="mc-table-body even">
            {trades?.length ? (
              <>
                {_.map(trades, (_r) => {
                  return (
                    <Tr key={_r?.id}>
                      <Td>{_r?.loginId}</Td>

                      <Td>{_r?.volume ? parseInt(_r?.volume) / 10000 : "-"}</Td>
                      <Td>{_r?.symbol}</Td>
                      <Td>{_r?.price}</Td>
                      <Td>{_r?.sl ?? 0}</Td>
                      <Td>{_r?.tp ?? 0}</Td>
                      <Td>{_r?.positionID}</Td>
                      <Td>{_r?.deal}</Td>
                      <Td>{_r?.order_id}</Td>
                      <Td>{_r?.action == 0 ? "Buy" : "Sell"}</Td>
                      <Td>Close</Td>
                      <Td>{_r?.comment}</Td>
                      <Td>{_r?.profit}</Td>
                      <Td>{convertDateToSeconds(_r?.time)}</Td>
                    </Tr>
                  )
                })}
              </>
            ) : (
              <Tr>
                <Td colSpan={thead?.length ?? 1}>
                  <div style={{ textAlign: "center", width: "100%" }}>
                    No Data found
                  </div>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  )
}
