import { Grid, Spinner } from "@chakra-ui/react"
import { useFormik, getIn } from "formik"
import React, { useEffect, useMemo, useState } from "react"
import * as Yup from "yup"
import _ from "lodash"
import { LabelField } from "../../../components/fields"
import { Box, Icon, Label } from "../../../components/elements"

import {
  addNewUser,
  adminCreateDepositWithdrawalRequest,
  adminCreateIBCommissionRequest,
  adminCreateTradingAccountService,
  changeUserActivePlanService,
} from "../../../services/admin.service"
import { useShowToast } from "../../../hooks"
import CustomReactSelect from "../../../components/fields/CustomReactSelect"

const thead = ["Level", "Commission Per Lot", ""]
const SignupSchema = Yup.object().shape({
  user_id: Yup.number().required("Required"),
  amount: Yup.number().required("Required"),
  comment: Yup.string(),
})

let type = ""
// accounts methods
const ChangeIbPlanForm = ({ ibs }) => {
  const showToast = useShowToast()

  const formik = useFormik({
    initialValues: {
      user_id: undefined,
      amount: 0,
      comment: "",
    },
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true)

      try {
        const result = await adminCreateIBCommissionRequest({
          ...values,
          type: "WITHDRAW",
        })

        if (result?.data?.success) {
          showToast("Success", "Request created successfully")
          actions.setSubmitting(false)
          actions.resetForm()
          return
        }
      } catch (e) {
        console.log(e)
      }

      showToast("Error", "Something went wrong", "error")
      actions.setSubmitting(false)
    },
    validationSchema: SignupSchema,
  })

  const allOptions = useMemo(() => {
    return [
      {
        label: "Select Account",
        value: undefined,
      },
      ..._.map(_.sortBy(ibs, "name"), (_u) => {
        return {
          label: `${_u?.name} (${_u?.email})`,
          value: _u?.id,
        }
      }),
    ]
  }, [ibs])

  const getValue = () => {}

  return (
    <>
      <br />
      <form onSubmit={formik.handleSubmit}>
        <Grid gap={4} my={4} maxWidth="500px">
          <Box>
            <CustomReactSelect
              options={allOptions}
              label="Select IB"
              errorMessage={
                formik?.errors?.user_id && formik?.touched?.user_id
                  ? formik?.errors?.user_id
                  : false
              }
              value={
                formik?.values?.user_id
                  ? _.find(allOptions, (opt) =>
                      opt?.value === formik?.values?.user_id ? true : false
                    )
                  : undefined
              }
              onChange={(e) => {
                formik?.setFieldValue("user_id", e?.value)
                formik?.setFieldTouched("user_id")
              }}
            />
          </Box>

          <LabelField
            label="Amount"
            type="number"
            name="amount"
            value={formik?.values?.amount}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            errorMessage={
              formik?.errors?.amount && formik?.touched?.amount
                ? formik?.errors?.amount
                : false
            }
          />

          <Box>
            <LabelField
              label="Comment"
              type="text"
              name="comment"
              value={formik?.values?.comment}
              onBlur={formik?.handleBlur}
              onChange={formik?.handleChange}
              errorMessage={
                formik?.errors?.comment && formik?.touched?.comment
                  ? formik?.errors?.comment
                  : false
              }
            />
          </Box>

          <Box style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ marginLeft: "auto" }}></div>

            <button
              type="submit"
              className="mc-btn primary"
              style={{ minHeight: "42px" }}
            >
              {formik?.isSubmitting ? <Spinner /> : "Create Request"}
            </button>
          </Box>
        </Grid>
      </form>
    </>
  )
}

export default ChangeIbPlanForm
