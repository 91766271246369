import React from "react"
import { CardHeader } from "../cards"
import { Dropdown } from "react-bootstrap"
import { DotsMenu, RoundAvatar } from ".."
import {
  Icon,
  Text,
  Box,
  Anchor,
  List,
  Item,
  Image,
  Heading,
  Button,
} from "../elements"
import _ from "lodash"
import { Link } from "react-router-dom"

export default function WidgetDropdown({
  title,
  icon,
  addClass,
  badge,
  dropdown,
  notifications,
}) {
  return (
    <Dropdown className={addClass}>
      <Dropdown.Toggle
        className="mc-dropdown-toggle mc-header-icon "
        title={title}
      >
        <Icon type={icon} />

        {badge ? (
          <Text as="sup" className="primary">
            {badge}
          </Text>
        ) : null}
      </Dropdown.Toggle>
      <Dropdown.Menu align="end" className="mc-dropdown-paper">
        <Box className="mc-header-dropdown-group">
          <CardHeader
            title={`${dropdown?.title} (${notifications?.length})`}
            dotsMenu={null}
          />

          <List
            className="mc-header-dropdown-list thin-scrolling mt-1"
            style={{ minHeight: "100%" }}
          >
            {_.map(notifications, (notify, index) => (
              <>
                <Item key={index} className={"p-1 border-bottom"}>
                  <small>{notify?.message ?? "-"}</small>
                </Item>
              </>
            ))}
          </List>
          <Link
            to={
              window?.location?.href?.includes("/admin")
                ? "/admin/notifications"
                : "/notifications"
            }
          >
            <Button
              className="mc-btn primary mx-auto mt-2"
              style={{ width: "100%" }}
            >
              View All
            </Button>
          </Link>
        </Box>
      </Dropdown.Menu>
    </Dropdown>
  )
}
