import React, { useContext } from "react"
import { AuthContext } from "../context/AuthProvider"

const useSession = () => {
  const { user, isLoading, setUser } = useContext(AuthContext)

  return [user, isLoading, setUser]
}

export default useSession
