import React from "react"
import { Box, Icon, Item, List, Text } from "./elements"
import _ from "lodash"

export default function Pagination({ currentPage, lastPage, setCurrentPage }) {
  return (
    <Box className="mc-paginate">
      <div></div>

      <List className="mc-paginate-list">
        {currentPage != 1 && (
          <Item
            className="mc-paginate-item"
            onClick={() => {
              setCurrentPage(currentPage - 1)
            }}
          >
            <Icon type="chevron_left" />
          </Item>
        )}

        {currentPage != 1 && (
          <Item
            className="mc-paginate-item "
            onClick={() => {
              setCurrentPage(currentPage - 1)
            }}
          >
            {currentPage - 1}
          </Item>
        )}

        <Item className="mc-paginate-item active">{currentPage}</Item>

        {currentPage != lastPage && (
          <Item
            className="mc-paginate-item "
            onClick={() => {
              setCurrentPage(currentPage + 1)
            }}
          >
            {currentPage + 1}
          </Item>
        )}

        {currentPage + 2 <= lastPage && (
          <Item
            className="mc-paginate-item "
            onClick={() => {
              setCurrentPage(currentPage + 2)
            }}
          >
            {currentPage + 2}
          </Item>
        )}

        {currentPage != lastPage && (
          <Item
            className="mc-paginate-item"
            onClick={() => {
              setCurrentPage(currentPage + 1)
            }}
          >
            <Icon type="chevron_right" />
          </Item>
        )}
      </List>
    </Box>
  )
}
