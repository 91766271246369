import React, { useState } from "react"
import { Row, Col, Form } from "react-bootstrap"
import { Box, Text, Item, Anchor, Button } from "../../components/elements"
import { LabelField, LabelTextarea } from "../../components/fields"
import { Breadcrumb, DivideTitle } from "../../components"
import CardLayout from "../../components/cards/CardLayout"
import PageLayout from "../../layouts/PageLayout"
import data from "../../data/master/transfer.json"
import useSession from "../../hooks/useSession"
import * as Yup from "yup"
import _ from "lodash"
import { useQuery } from "react-query"
import {
  createRequestService,
  createTransferRequestService,
  getMyAccounts,
  getMyLiveDemoAccounts,
  getPaymentMethodsService,
} from "../../services/client"
import { useFormik } from "formik"
import { useShowToast } from "../../hooks/useShowToast"
import { Skeleton, Spinner } from "@chakra-ui/react"
import VerifyOtpRequestModal from "../../components/VerifyOtpRequestModal"
import EditUsersDetailsModal, {
  EditUserProfileForm,
} from "../admin/users/EditUserDetailsModal"

export default function MyProfile() {
  const [user] = useSession()

  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={"My Profile"}>
          <Item className="mc-breadcrumb-item">Home</Item>
          <Item className="mc-breadcrumb-item">My Profile</Item>
        </Breadcrumb>
      </CardLayout>
      {user ? (
        <>
          <CardLayout className="p-sm-5">
            <Box className="mb-5">
              <Row>
                <Col xl={8}>
                  <EditUserProfileForm
                    user={user}
                    setUser={() => {}}
                    refetch={() => {
                      window.location.reload()
                    }}
                  />
                </Col>
              </Row>
            </Box>
          </CardLayout>
        </>
      ) : null}
    </PageLayout>
  )
}
