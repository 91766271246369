import { useEffect } from "react"
import { FullPageLoader } from "../components/loader"
import useSession from "../hooks/useSession"
import { isEmpty } from "lodash"
import { redirectToLogin } from "../utils"

export const WithAuth = ({ children }) => {
  const [user, isLoading, setUser] = useSession()

  useEffect(() => {
    if (!isLoading && isEmpty(user)) {
      redirectToLogin()
    }
  }, [user, isLoading])

  if (isLoading || isEmpty(user)) return <FullPageLoader />

  return <> {children} </>
}
