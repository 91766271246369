import DatePickerReact, { CalendarContainer } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { FormWrapper } from "./elements/Input"
import { forwardRef } from "react"
import { LabelField } from "./fields"
import formatDateHelper from "../utils/formatDate"
import moment from "moment"
import { Flex, Box } from "@chakra-ui/react"
import { List } from "./elements"
import _ from "lodash"
import { subDays, subMonths } from "date-fns"

export const DateRangePicker = (props) => {
  const { label, errorMessage, startDate, endDate, onChange } = props

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <LabelField
      type="text"
      label={label}
      errorMessage={errorMessage}
      onClick={onClick}
      ref={ref}
      value={
        startDate
          ? `${moment(startDate).format("Do MMM YYYY")} to ${
              endDate
                ? moment(endDate).format("Do MMM YYYY")
                : moment(startDate).format("Do MMM YYYY")
            }`
          : "Select Date range"
      }
    />
  ))

  const MyContainer = (props) => {
    const { className, children, onChange } = props

    const list = [
      "Today",
      "Last 3 days",
      "Last Week",
      "Last month",
      "Last 3 months",
      "Last 6 months",
      "All History",
    ]

    const onClickSelectedItem = (_t) => {
      switch (_t) {
        case list[0]:
          onChange([new Date(), new Date()])
          break

        case list[1]:
          onChange([subDays(new Date(), 3), new Date()])
          break

        case list[2]:
          onChange([subDays(new Date(), 7), new Date()])
          break

        case list[3]:
          onChange([subMonths(new Date(), 1), new Date()])

        case list[4]:
          onChange([subMonths(new Date(), 3), new Date()])
          break

        case list[5]:
          onChange([subMonths(new Date(), 6), new Date()])
          break

        case list[6]:
          onChange([undefined, undefined])
          break

        default:
          break
      }
    }

    return (
      <CalendarContainer className={className}>
        <Flex style={{ background: "#f0f0f0" }}>
          <Box py={2}>
            {_.map(list, (_l, index) => (
              <Box
                p={2}
                borderBottom={"1px solid "}
                borderBottomColor={
                  index + 1 === list.length
                    ? "transparent"
                    : "var(--chakra-colors-gray-400)"
                }
                fontSize="12px"
                cursor="pointer"
                onClick={() => {
                  onClickSelectedItem(_l)
                }}
              >
                {_l}
              </Box>
            ))}
          </Box>
          <div
            style={{
              position: "relative",
              borderLeft: "1px solid",
              borderLeftColor: "var(--chakra-colors-gray-400)",
            }}
          >
            {children}
          </div>
        </Flex>
      </CalendarContainer>
    )
  }

  return (
    <>
      <DatePickerReact
        customInput={<ExampleCustomInput />}
        calendarContainer={(props) => (
          <MyContainer
            startDate={startDate}
            endDate={endDate}
            onChange={onChange}
            {...props}
          />
        )}
        {...props}
      />
    </>
  )
}

export default DateRangePicker
