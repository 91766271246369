import _ from "lodash"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Grid,
  Box,
  Spinner,
} from "@chakra-ui/react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { LabelField } from "../../../components/fields"
import { updateUserService } from "../../../services/admin.service"
import { useShowToast } from "../../../hooks"

const userSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  country: Yup.string().nullable(),
  phone: Yup.string().nullable(),
  address: Yup.string().nullable(),
})

const EditUsersDetailsModal = ({ user, setUser, refetch }) => {
  const onClose = () => {
    setUser(null)
  }

  if (_.isEmpty(user)) return <></>

  return (
    <>
      <Modal
        isOpen={!_.isEmpty(user) ? true : false}
        onClose={onClose}
        isCentered
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <EditUserProfileForm
              user={user}
              setUser={setUser}
              refetch={refetch}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default EditUsersDetailsModal

export const EditUserProfileForm = ({ user, setUser, refetch }) => {
  const toast = useShowToast()
  const formik = useFormik({
    initialValues: {
      name: user?.name ?? "",
      country: user?.country ?? "",
      phone: user?.phone ?? "",
      address: user?.address ?? "",
    },
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      try {
        const result = await updateUserService({ ...values, user_id: user?.id })

        if (result?.data?.success) {
          toast("Success", "Profile updated successfully")

          if (refetch) {
            await refetch()
          }

          setUser(null)
        } else {
          throw new Error(result?.data?.message ?? "Something went wrong")
        }
      } catch (error) {
        toast("Error", "Something went wrong", "error")
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid gap={4} my={4}>
        <LabelField
          label="Name"
          type="text"
          name="name"
          value={formik?.values?.name}
          onBlur={formik?.handleBlur}
          onChange={formik?.handleChange}
          errorMessage={
            formik?.errors?.name && formik?.touched?.name
              ? formik?.errors?.name
              : false
          }
        />

        <LabelField
          label="Country"
          type="text"
          name="country"
          value={formik?.values?.country}
          onBlur={formik?.handleBlur}
          onChange={formik?.handleChange}
          errorMessage={
            formik?.errors?.country && formik?.touched?.country
              ? formik?.errors?.country
              : false
          }
        />

        <LabelField
          label="Phone"
          type="text"
          name="phone"
          value={formik?.values?.phone}
          onBlur={formik?.handleBlur}
          onChange={formik?.handleChange}
          errorMessage={
            formik?.errors?.phone && formik?.touched?.phone
              ? formik?.errors?.phone
              : false
          }
        />

        <LabelField
          label="address"
          type="text"
          name="address"
          value={formik?.values?.address}
          onBlur={formik?.handleBlur}
          onChange={formik?.handleChange}
          errorMessage={
            formik?.errors?.address && formik?.touched?.address
              ? formik?.errors?.address
              : false
          }
        />

        <Box style={{ display: "flex", alignItems: "flex-end" }}>
          <div style={{ marginLeft: "auto" }}></div>

          <button
            type="submit"
            className="mc-btn primary"
            style={{ minHeight: "42px" }}
            disabled={formik?.isSubmitting}
          >
            {formik?.isSubmitting ? <Spinner /> : "Update"}
          </button>
        </Box>
      </Grid>
    </form>
  )
}
