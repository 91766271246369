import ReactSelect from "react-select"
import { FormWrapper } from "../elements/Input"

const CustomReactSelect = (props) => {
  const { label, errorMessage } = props

  return (
    <>
      <FormWrapper label={label} errorMessage={errorMessage}>
        <ReactSelect classNamePrefix="reactSelect" {...props} />
      </FormWrapper>
    </>
  )
}

export default CustomReactSelect
