import React, { useEffect, useMemo, useRef, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { Breadcrumb } from "../../../../components"
import PageLayout from "../../../../layouts/PageLayout"

import { Box, Item, Anchor, Button } from "../../../../components/elements"
import { CardLayout } from "../../../../components/cards"
import { Skeleton, Grid, Switch } from "@chakra-ui/react"
import { LabelField } from "../../../../components/fields"
import { useQuery } from "react-query"
import {
  addAdminAccount,
  getAllUsersServices,
} from "../../../../services/admin.service"
import { useShowToast } from "../../../../hooks"
import SearchUserField from "../../../../components/admin/SearchUser"
import _ from "lodash"
import { getAccountPlans } from "../../../../services/client"
import * as Yup from "yup"
import { useFormik } from "formik"
import { FormWrapper } from "../../../../components/elements/Input"
import CustomReactSelect from "../../../../components/fields/CustomReactSelect"

export default function AdminUserAccounts() {
  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <Box className="mc-card">
            <Breadcrumb title="Add Existing MT5 Account to a user">
              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href="/admin">
                  Home
                </Anchor>
              </Item>

              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href="/admin">
                  Add Existing MT5 Account to a user
                </Anchor>
              </Item>
            </Breadcrumb>
          </Box>
        </Col>

        <DepositsPaymentPage />
      </Row>
    </PageLayout>
  )
}

const Loader = () => {
  return <Skeleton minHeight="500px" />
}
// account_id user_id is_live password master_password platform
// leverage currency is_live group account_master_id

const addAccountSchema = Yup.object().shape({
  account_id: Yup.number().required("Required"),
  user_id: Yup.number().required("Required"),
  account_master_id: Yup.number().required("Required"),
  is_live: Yup.boolean().required("Required"),
  password: Yup.string().required("Required"),
  master_password: Yup.string().required("Required"),
  leverage: Yup.number().required("Required"),
  currency: Yup.string().required("Required"),
})

const DepositsPaymentPage = () => {
  const [type, setType] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [modifyRequest, setModifyRequest] = useState(null)
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)
  const showToast = useShowToast()
  const [selectedUser, setSelectedUser] = useState(undefined)
  const [accountId, setAccountId] = useState(undefined)
  const [isDownloading, setIsDownloading] = useState(false)
  const [forceSearch, setForceSearch] = useState(0.01)
  const [changePasswordAcc, setChangePasswordAcc] = useState(undefined)
  const [name, setName] = useState("")
  const [finalState, setFinalState] = useState({
    type,
    name,
    selectedUser,
    accountId,
  })

  const { isLoading, error, data, refetch } = useQuery(
    ["admin-all-users", currentPage, forceSearch],
    () => getAllUsersServices(currentPage, "", 30000)
  )

  const account_plans = useQuery(["account_plans"], () => getAccountPlans())

  const formik = useFormik({
    initialValues: {
      account_id: undefined,
      user_id: undefined,
      account_master_id: undefined,
      is_live: true,
      password: "",
      master_password: "",
      leverage: undefined,
      currency: "",
    },
    validationSchema: addAccountSchema,
    onSubmit: async (_values, actions) => {
      // account_id user_id account_master_id is_live password
      // master_password platform leverage currency  group

      actions.setSubmitting(true)

      console.log(_values)
      try {
        const accMaster = _.find(
          account_plans?.data?.data?.data?.account_master,
          (_a) => (_a?.id === _values?.account_master_id ? true : false)
        )
        const values = {
          ..._values,
          group: _values?.is_live ? accMaster?._Group : accMaster?.demo_group,
          is_ib: 0,
          platform: "MT5",
          is_live: _values?.is_live ? 1 : 0,
          status: "APPROVED",
        }

        const result = await addAdminAccount(values)

        if (result?.data?.success) {
          showToast("Success", "Account added successfully")
          formik.resetForm()
          formik.setFieldValue("account_id", "")
          formik.setFieldValue("leverage", "")
        } else {
          showToast(
            "Error",
            result?.data?.message ?? "Something went wrong",
            "error"
          )
        }

        console.log(result)
      } catch (error) {
        console.log(error)
      }

      actions.setSubmitting(false)
    },
  })

  const userOptions = useMemo(() => {
    return [
      {
        label: "Select User",
        value: undefined,
      },
      ..._.map(_.sortBy(data?.data?.data?.users?.data ?? [], "id"), (_u) => {
        return {
          label: _u?.id + "  " + _u?.name + "  " + _u?.email,
          value: _u?.id,
        }
      }),
    ]
  }, [isLoading, data])

  const planOptions = useMemo(() => {
    return [
      {
        label: "Select Plan",
        value: undefined,
      },
      ..._.map(
        _.sortBy(account_plans?.data?.data?.data?.account_master ?? [], "id"),
        (_u) => {
          return {
            label: _u?.title + "  (Leverage : " + _u?.Leverage + ")",
            value: _u?.id,
          }
        }
      ),
    ]
  }, [account_plans.isLoading, account_plans.data])

  if (isLoading || account_plans.isLoading)
    return (
      <>
        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col>
                <Loader />
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </>
    )

  return (
    <>
      <Col xl={12}>
        <CardLayout>
          <Row>
            <Col>
              <form onSubmit={formik.handleSubmit}>
                <Grid gap={4} my={4} maxWidth="500px">
                  <Box>
                    <LabelField
                      label="Login ID"
                      type="number"
                      name="account_id"
                      value={formik?.values?.account_id}
                      onBlur={formik?.handleBlur}
                      onChange={formik?.handleChange}
                      errorMessage={
                        formik?.errors?.account_id &&
                        formik?.touched?.account_id
                          ? formik?.errors?.account_id
                          : false
                      }
                    />
                  </Box>

                  <Box>
                    <CustomReactSelect
                      label="Select User"
                      options={userOptions}
                      errorMessage={
                        formik?.errors?.user_id && formik?.touched?.user_id
                          ? formik?.errors?.user_id
                          : false
                      }
                      value={
                        formik?.values?.user_id
                          ? _.find(
                              userOptions,
                              (_u) => _u?.id === formik?.values?.user_id
                            )
                          : {
                              label: "Select User",
                              value: undefined,
                            }
                      }
                      onChange={(e) => {
                        formik.setFieldTouched("user_id")
                        formik.setFieldValue("user_id", e?.value)
                      }}
                      onBlur={() => {
                        formik.setFieldTouched("user_id")
                      }}
                    />
                  </Box>

                  <Box>
                    <CustomReactSelect
                      label="Select Plan"
                      options={planOptions}
                      errorMessage={
                        formik?.errors?.account_master_id &&
                        formik?.touched?.account_master_id
                          ? formik?.errors?.account_master_id
                          : false
                      }
                      value={
                        formik?.values?.account_master_id
                          ? _.find(
                              userOptions,
                              (_u) =>
                                _u?.id === formik?.values?.account_master_id
                            )
                          : {
                              label: "Select Plan",
                              value: undefined,
                            }
                      }
                      onChange={(e) => {
                        formik.setFieldTouched("account_master_id")
                        formik.setFieldValue("account_master_id", e?.value)
                      }}
                      onBlur={() => {
                        formik.setFieldTouched("account_master_id")
                      }}
                    />
                  </Box>

                  <Box>
                    <FormWrapper
                      label="Is Live"
                      errorMessage={
                        formik?.errors?.is_live && formik?.touched?.is_live
                          ? formik?.errors?.is_live
                          : false
                      }
                    >
                      <>
                        <br />
                        <Switch
                          isChecked={formik?.values.is_live}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "is_live",
                              !formik?.values.is_live
                            )
                            formik.setFieldTouched("is_live")
                          }}
                        />{" "}
                        &nbsp; {formik?.values.is_live ? "Yes" : "No"}
                      </>
                    </FormWrapper>
                  </Box>

                  <Box>
                    <LabelField
                      label="Investor Password"
                      type="text"
                      name="password"
                      value={formik?.values?.password}
                      onBlur={formik?.handleBlur}
                      onChange={formik?.handleChange}
                      errorMessage={
                        formik?.errors?.password && formik?.touched?.password
                          ? formik?.errors?.password
                          : false
                      }
                    />
                  </Box>

                  <Box>
                    <LabelField
                      label="Master Password"
                      type="text"
                      name="master_password"
                      value={formik?.values?.master_password}
                      onBlur={formik?.handleBlur}
                      onChange={formik?.handleChange}
                      errorMessage={
                        formik?.errors?.master_password &&
                        formik?.touched?.master_password
                          ? formik?.errors?.master_password
                          : false
                      }
                    />
                  </Box>

                  <Box>
                    <LabelField
                      label="Leverage"
                      type="number"
                      name="leverage"
                      value={formik?.values?.leverage}
                      onBlur={formik?.handleBlur}
                      onChange={formik?.handleChange}
                      errorMessage={
                        formik?.errors?.leverage && formik?.touched?.leverage
                          ? formik?.errors?.leverage
                          : false
                      }
                    />
                  </Box>

                  <Box>
                    <LabelField
                      label="currency"
                      type="text"
                      name="currency"
                      value={formik?.values?.currency}
                      onBlur={formik?.handleBlur}
                      onChange={formik?.handleChange}
                      errorMessage={
                        formik?.errors?.currency && formik?.touched?.currency
                          ? formik?.errors?.currency
                          : false
                      }
                    />
                  </Box>

                  <Box>
                    <Button
                      isLoading={formik?.isSubmitting}
                      disabled={formik.isSubmitting}
                      type="submit"
                      className="mc-btn primary"
                      minHeight="42px"
                    >
                      {formik?.isSubmitting ? "Adding..." : "Add"}
                    </Button>
                  </Box>
                </Grid>
              </form>
            </Col>
          </Row>
        </CardLayout>
      </Col>
    </>
  )
}
