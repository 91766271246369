import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Tab, Tabs, Form } from "react-bootstrap"
import { Breadcrumb, Pagination } from "../../../../components"
import PageLayout from "../../../../layouts/PageLayout"
import { Box, Item, Anchor, Button } from "../../../../components/elements"
import { CardLayout } from "../../../../components/cards"
import {
  Skeleton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Flex,
  Spinner,
  Input,
} from "@chakra-ui/react"
import { LabelField } from "../../../../components/fields"
import DepositTable from "./table"
import { useQuery } from "react-query"
import {
  approveRejectRequestService,
  getRequestsByType,
} from "../../../../services/admin.service"
import { useShowToast } from "../../../../hooks"
import SearchUser from "../../../../components/admin/SearchUser"
import _ from "lodash"
import { formatDateHelper } from "./../../../../utils/formatDate"
import downloadFile from "./../../../../utils/downloadFile"
import convertJsonToCsv from "./../../../../utils/convertJsonToCsv"
import { getSearchParams } from "../../../../utils"
import moment from "moment"
import DateRangePicker from "../../../../components/DateRangePicker"

export default function CreditHistory() {
  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <Box className="mc-card">
            <Breadcrumb title="Credit History">
              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href="/admin">
                  Home
                </Anchor>
              </Item>

              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href="/admin">
                  Credit History
                </Anchor>
              </Item>
            </Breadcrumb>
          </Box>
        </Col>

        <DepositsPaymentPage />
      </Row>
    </PageLayout>
  )
}

const Loader = () => {
  return <Skeleton minHeight="500px" />
}

const DepositsPaymentPage = () => {
  const [type, setType] = useState(getSearchParams("status"))
  const [currentPage, setCurrentPage] = useState(null)
  const [modifyRequest, setModifyRequest] = useState(null)
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)
  const showToast = useShowToast()
  const [selectedUser, setSelectedUser] = useState(undefined)
  const [isDownloading, setIsDownloading] = useState(false)
  const [forceSearch, setForceSearch] = useState(0.01)
  const [name, setName] = useState("")
  const [reqType, setReqType] = useState("CREDITIN")
  const [dateRange, setDateRange] = useState([])

  const [finalState, setFinalState] = useState({
    name,
    selectedUser,
    type,
    reqType,
    from: undefined,
    to: undefined,
  })

  const { isLoading, error, data, refetch } = useQuery(
    ["admin-credit-transfer-requests", finalState, currentPage],
    () =>
      getRequestsByType(
        currentPage,
        finalState.reqType,
        type,
        finalState.selectedUser,
        false,
        finalState.name,
        "",
        finalState.from,
        finalState.to
      )
  )

  useEffect(() => {
    setFinalState({
      name,
      selectedUser,
      type,
      reqType: reqType,
      from:
        dateRange?.length == 2 && dateRange[0]
          ? moment(dateRange[0]).format("YYYY-MM-DD")
          : undefined,
      to:
        dateRange?.length == 2 && dateRange[1]
          ? moment(dateRange[1]).format("YYYY-MM-DD")
          : undefined,
    })
    setCurrentPage(1)
  }, [forceSearch])

  useEffect(() => {
    setCurrentPage(1)
  }, [type, selectedUser])

  const total = data?.data?.data?.walletRequest?.total
  const requests = data?.data?.data?.walletRequest?.data ?? []

  const last_page = data?.data?.data?.walletRequest?.last_page ?? 1

  const handleChangeStatus = async (comment) => {
    setIsUpdatingStatus(true)

    try {
      const result = await approveRejectRequestService(
        modifyRequest?.req?.id,
        modifyRequest?.status === "accept" ? "ACCEPTED" : "REJECTED",
        comment
      )

      if (result?.data?.success) {
        await refetch()
        showToast("Success", "Request Updated Successfully")
        setIsUpdatingStatus(false)
        setModifyRequest(null)
        return
      } else {
      }
    } catch (error) {
      console.log(error)
    }
    showToast("Error", "Something went wrong", "error")
    setIsUpdatingStatus(false)
  }

  const handleCSVDownload = async () => {
    setIsDownloading(true)

    try {
      const result = await getRequestsByType(
        currentPage,
        reqType,
        type,
        selectedUser,
        true,
        "",
        finalState.from,
        finalState.to
      )
      // ID, ACCOUNT,
      const formattedData = _.map(result?.data?.data?.walletRequest, (_r) => {
        const _request = _r

        return {
          ID: _request?.id,
          ACCOUNT: _request?.account?.account_id,
          NAME: _request?.created_by?.name ?? "",
          EMAIL: _request?.created_by?.email ?? "",
          CREATED_AT: formatDateHelper(_request?.created_at, true),
          TYPE: _request?.transactedWith?.title,
          AMOUNT: _request?.amount,
          STATUS: _request?.status,
          REFERENCE: _request?.reference,
        }
      })

      //      console.log()
      downloadFile({
        data: convertJsonToCsv(formattedData, true),
        fileName: "credit-transfer.csv",
        fileType: "text/csv",
      })
    } catch (error) {
      console.error("Error downloading file:", error)
    }

    setIsDownloading(false)
  }

  return (
    <>
      <Col xl={12}>
        <CardLayout>
          <Row>
            <Col xl={3}>
              <DateRangePicker
                label="Select Date Range"
                selected={dateRange[0]}
                onChange={setDateRange}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                selectsRange
                //inline
              />
            </Col>
            <Col xl={2}>
              <LabelField
                name="Type"
                label="Select Credit Type"
                option={[
                  { title: "CREDIT IN", value: "CREDITIN" },
                  { title: "CREDIT OUT", value: "CREDITOUT" },
                ]}
                value={reqType}
                onChange={(e) => {
                  setReqType(e?.target?.value)
                }}
              />
            </Col>

            <Col xl={2}>
              <LabelField
                name="Type"
                label="Select Stage"
                option={[
                  { title: "ALL", value: undefined },
                  { title: "PENDING", value: "PENDING" },
                  { title: "ACCEPTED", value: "ACCEPTED" },
                  { title: "REJECTED", value: "REJECTED" },
                ]}
                value={type}
                onChange={(e) => {
                  setType(e?.target?.value)
                }}
              />
            </Col>
            <Col xl={3}>
              <SearchUser
                userId={selectedUser}
                onChangeUserId={setSelectedUser}
              />
            </Col>

            <Col xl={2}>
              <div
                style={{
                  display: "inline-flex",
                  marginTop: "24px",
                  textAlign: "right",
                  flexDirection: "column",
                  width: "100%",
                  maxWidth: "120px",
                }}
              >
                <div>
                  <Button
                    onClick={() => {
                      handleCSVDownload()
                    }}
                    className="mc-btn green mb-2"
                  >
                    {isDownloading ? "Downloading" : "Download"}
                  </Button>
                </div>

                <div>
                  <Button
                    style={{
                      marginRight: "12px",
                    }}
                    onClick={() => {
                      setForceSearch((_v) => _v + 0.01)
                    }}
                    className="mc-btn primary "
                  >
                    Search
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          <br />

          {isLoading ? (
            <Loader />
          ) : (
            <>
              <DepositTable
                requests={requests ?? []}
                onChangeStatus={(_val) => {
                  setModifyRequest(_val)
                }}
              />
              <br />
              <Pagination
                currentPage={currentPage}
                lastPage={last_page}
                setCurrentPage={setCurrentPage}
              />
            </>
          )}
        </CardLayout>
      </Col>
      <ModifyRequestAlert
        request={modifyRequest}
        setRequest={setModifyRequest}
        isUpdatingStatus={isUpdatingStatus}
        onRequestChange={(_c) => handleChangeStatus(_c)}
      />
    </>
  )
}

const ModifyRequestAlert = ({
  request,
  setRequest,
  isUpdatingStatus,
  onRequestChange,
}) => {
  const cancelRef = useRef()
  const [comment, setComment] = useState("")

  useEffect(() => {
    if (!request) {
      setComment("")
    }
  }, [request])

  return (
    <AlertDialog
      isOpen={request ? true : false}
      leastDestructiveRef={cancelRef}
      onClose={() => {
        setRequest(null)
      }}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            CONFIRM {request?.req?.type} ?
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure, You want {request?.status} request made by &nbsp;
            <b>{request?.req?.created_by?.name}</b> for{" "}
            <b>{request?.req?.amount} USDT</b> ?
            <Input
              my={4}
              placeholder="Enter comment if any "
              value={comment}
              onChange={(e) => {
                setComment(e?.target?.value)
              }}
            />
          </AlertDialogBody>

          <AlertDialogFooter>
            <Flex>
              <div style={{ marginRight: "4px" }}>
                {!isUpdatingStatus ? (
                  <button
                    onClick={() => {
                      setRequest(null)
                    }}
                    className="mc-btn gray"
                    style={{ textTransform: "uppercase", minHeight: "32px" }}
                  >
                    Cancel
                  </button>
                ) : (
                  <></>
                )}
              </div>

              <div style={{ marginRight: "4px" }}>
                <button
                  onClick={() => {
                    onRequestChange(comment)
                  }}
                  className="mc-btn green"
                  style={{ textTransform: "uppercase", minHeight: "32px" }}
                >
                  {isUpdatingStatus ? <Spinner /> : request?.status}
                </button>
              </div>
            </Flex>

            {/* <Button
              ref={cancelRef}
              onClick={() => {
                setRequest(null)
              }}
            >
              Cancel
            </Button>
            <Button
              className="mc-bn red"
              onClick={() => {
                setRequest(null)
              }}
              ml={3}
            >
              Delete
            </Button> */}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
