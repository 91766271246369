const CONFIG_LS_KEY = "0.0.1_app-crm-config-ocrm"

export const CONFIG_KEYS = {
  AI_FORM_MODAL_SHOWN: "AI_FORM_MODAL_SHOWN",
  NEVER_SHOW_AI_FORM_MODAL_SHOWN: "NEVER_SHOW_AI_FORM_MODAL_SHOWN",
}

const DEFAULT_CONFIG = {
  [CONFIG_KEYS.AI_FORM_MODAL_SHOWN]: false,
  [CONFIG_KEYS.NEVER_SHOW_AI_FORM_MODAL_SHOWN]: false,
}

export const getConfig = (key) => {
  const configs = JSON.parse(
    localStorage.getItem(CONFIG_LS_KEY) ?? JSON.stringify(DEFAULT_CONFIG)
  )

  return configs[key] ?? null
}

export const setConfig = (key, value) => {
  let configs = JSON.parse(
    localStorage.getItem(CONFIG_LS_KEY) ?? JSON.stringify(DEFAULT_CONFIG)
  )

  configs[key] = value
  localStorage.setItem(CONFIG_LS_KEY, JSON.stringify(configs))

  return true
}
