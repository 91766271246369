import React, { useMemo, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { Box, Button, Item } from "../../components/elements"
import { CardLayout, CardHeader } from "../../components/cards"
import { Breadcrumb, Pagination } from "../../components"
import PageLayout from "../../layouts/PageLayout"
import useSession from "../../hooks/useSession"
import { Skeleton } from "@chakra-ui/react"
import { useQuery } from "react-query"
import { getNotifications, getTradesService } from "../../services/client"
import _ from "lodash"
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "../../components/elements/Table"
import convertDateToSeconds from "../../utils/convertSecondsToDate"
import { LabelField } from "../../components/fields"
import { getAllAccountsService } from "../../services/admin.service"
import DateRangePicker from "../../components/DateRangePicker"
import formatDateHelper, { formatDateToYYYYMMDD } from "../../utils/formatDate"
import { useEffect } from "react"
import downloadFile from "../../utils/downloadFile"
import convertJsonToCsv from "../../utils/convertJsonToCsv"
import { Link } from "react-router-dom"

export default function AdminNotifications() {
  const [user] = useSession()

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title="Notifications">
              <Item className="mc-breadcrumb-item">Home</Item>
              <Item className="mc-breadcrumb-item">Notifications</Item>
            </Breadcrumb>
          </CardLayout>
        </Col>
      </Row>
      {user ? <TransactionsContainer user={user} /> : null}
    </PageLayout>
  )
}

const TransactionsLoader = () => {
  return <Skeleton minHeight="500px" />
}

const TransactionsContainer = ({ user }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(true)
  const [selectAccount, setSelectedAccount] = useState(null)
  const [forcedSearch, setForcedSearch] = useState(false)
  const [dateRange, setDateRange] = useState([undefined, undefined])
  const [finalState, setFinalState] = useState({
    dateRange,
    selectAccount,
  })
  const [isDownloading, setIsDownloading] = useState(false)

  const { isLoading, error, data } = useQuery(
    ["admin-notifications", currentPage, forcedSearch, finalState],
    () => getNotifications(currentPage),
    {
      refetchInterval: 4500,
    }
  )

  useEffect(() => {
    setFinalState({
      dateRange,
      selectAccount,
    })
  }, [forcedSearch])

  const notifications = data?.data?.data?.notifications ?? {}

  if (isLoading) return <TransactionsLoader />

  return (
    <Row>
      <Col xl={12}>
        <CardLayout>
          <Row className="d-none">
            <Col xl={3}>
              <DateRangePicker
                label="Select Date Range"
                selected={dateRange[0]}
                onChange={setDateRange}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                selectsRange
                //inline
              />
            </Col>

            <Col xl={3}></Col>

            <Col xl={3}> </Col>

            <Col xl={3}>
              <div style={{ textAlign: "right" }}>
                <br />
                &emsp;
                <Button
                  onClick={() => {
                    setCurrentPage(1)
                    setForcedSearch((v) => !v)
                  }}
                  className="mc-btn primary ml-auto"
                >
                  Search
                </Button>
              </div>
            </Col>
          </Row>

          <br />

          <MyTradesTable trades={notifications.data ?? []} />
          <br />
          <Pagination
            lastPage={notifications?.last_page}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </CardLayout>
      </Col>
    </Row>
  )
}

const thead = ["Message", "Created At", "Seen At"]

const MyTradesTable = ({ trades }) => {
  return (
    <>
      <Box className="mc-table-responsive">
        <Table className="mc-table product">
          <Thead className="mc-table-head primary">
            <Tr>
              {thead.map((item, index) => (
                <Th key={index}>{item}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody className="mc-table-body even">
            {trades?.length ? (
              <>
                {_.map(trades, (_r) => {
                  return (
                    <Tr key={_r?.id}>
                      <Td>{_r?.message}</Td>
                      <Td>{formatDateHelper(_r?.created_at)}</Td>
                      <Td>
                        {_r?.seen_at ? formatDateHelper(_r?.created_at) : "-"}
                      </Td>
                    </Tr>
                  )
                })}
              </>
            ) : (
              <Tr>
                <Td colSpan={thead?.length ?? 1}>
                  <div style={{ textAlign: "center", width: "100%" }}>
                    No Data found
                  </div>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  )
}
