function convertJsonToCsv(jsonArray) {
  // Extract headers from the first object in the array
  const headers = Object.keys(jsonArray[0])

  // Create the CSV header row
  const csvHeader = headers.join(",")

  // Create the CSV data rows
  const csvRows = jsonArray.map((obj) => {
    return headers
      .map((header) => {
        const value = obj[header]

        // If the value contains spaces, wrap it in double quotes
        if (typeof value === "string" && value.includes(" ")) {
          return `"${value}"`
        }

        return value
      })
      .join(",")
  })

  // Combine the header and rows to form the complete CSV string
  const csvString = [csvHeader, ...csvRows].join("\n")

  return csvString
}

export default convertJsonToCsv
