import React, { useState } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
} from "@chakra-ui/react"
import { Button, Text } from "./elements"
import OTPInput from "react-otp-input"
import { verifyOTPService } from "../services/auth.service"
import { verifyTransactionOtpService } from "../services/client"
import { useShowToast } from "../hooks"

const VerifyOtpRequestModal = ({
  request,
  setRequest,
  resetForm,
  resetImage,
}) => {
  const [otp, setOtp] = useState("")
  const [isVerifying, setIsverifying] = useState(false)
  const toast = useShowToast()

  const handleVerifyOtp = async () => {
    setIsverifying(true)

    try {
      const result = await verifyTransactionOtpService(request?.id, otp)

      if (result?.data?.success) {
        toast("Success", "Request verified  successfully")
        resetForm()
        setOtp("")
        setRequest(null)
        resetImage()
      } else {
        toast("Error", "Incorrect OTP", "error")
      }
    } catch (error) {}

    setIsverifying(false)
  }

  return (
    <>
      <Modal
        isOpen={request ? true : false}
        onClose={() => {
          setOtp("")
          setRequest(null)
        }}
        isCentered
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter OTP</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <br />
            <Text as="span">
              An otp has been sent to your registered email address, Please
              enter it to confirm your request.
            </Text>
            <OTPInput
              shouldAutoFocus
              containerStyle={{
                justifyContent: "center",
                margin: "10px 0",
              }}
              inputStyle={{
                border: "1px solid gray",
                margin: "2px",
                width: "20px",
              }}
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
            <br />
            <div style={{ textAlign: "right", width: "100%" }}>
              <button
                type="button"
                className="mc-btn primary ml-auto"
                disabled={isVerifying || otp?.length < 4 ? true : false}
                style={{
                  opacity: isVerifying || otp?.length < 4 ? 0.6 : 1,
                }}
                onClick={(e) => {
                  e.preventDefault()
                  handleVerifyOtp()
                }}
              >
                <div>{isVerifying ? <Spinner size="xs" /> : "Verify Now"}</div>
              </button>
            </div>

            <br />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default VerifyOtpRequestModal
