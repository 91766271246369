import { ThemeProvider } from "./context/Themes"
import "./App.css"
import { AuthContextProvider } from "./context/AuthProvider"
import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import { QueryClient, QueryClientProvider } from "react-query"
import Navigation from "./Navigation"
import { IBContextProvider } from "./context/IBContext"
import { BrowserRouter } from "react-router-dom"
import APP from "./config/app"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

export const queryClient = new QueryClient()

const theme = extendTheme({
  fonts: {
    heading: `"Crimson Text", serif`,
    body: `"Work Sans", sans-serif`,
  },
})

export default function App() {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={APP.ReCAPTCHA_KEY}
      useRecaptchaNet={true}
    >
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme}>
            <AuthContextProvider>
              <IBContextProvider>
                <ThemeProvider>
                  <Navigation />
                </ThemeProvider>
              </IBContextProvider>
            </AuthContextProvider>
          </ChakraProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </GoogleReCaptchaProvider>
  )
}
