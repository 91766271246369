import React, { useState, Suspense, lazy } from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { Overview, Documentation, ChangeLog, Error } from "./pages/supports"
import {
  Ecommerce,
  ForgotPassword,
  Register,
  Login,
  MyAccount,
} from "./pages/master"

import { Logout } from "./pages/master/Logout"
import { WithoutAdmin } from "./hoc/WithoutAdmin"
import { WithAdmin } from "./hoc"
import { FullPageLoader } from "./components/loader"

import AdminAddNewUser from "./pages/admin/add-new-user"
import AdminAddTradingAccount from "./pages/admin/add-new-trading-account"
import AdminDepositWithDrawalRequest from "./pages/admin/user-deposit-manual"
import AdminAddInternalTransferRequest from "./pages/admin/user-internal-transfer"
import MyDownloads from "./pages/master/Downloads"
import MyTrades from "./pages/master/MyTrades"
import IBTrades from "./pages/ib/IBTrades"
import AdminIBWithdrawRequests from "./pages/admin/ib-withdraw-requests"
import AdminSettings from "./pages/admin/settings"
import AdminSupport from "./pages/admin/support"
import AdminSupportOneView from "./pages/admin/support/OneView"
import OpenTrades from "./pages/master/OpenTrades"
import AdminDepositIBCommission from "./pages/admin/admin-ib-commission-deposit"
import AdminWithdrawIBCommissionRequest from "./pages/admin/admin-ib-commission-withdraw-request"
import AdminManageRoles from "./pages/admin/manage-roles"
import AITermsAndConditions from "./pages/master/AITermsAndConditions"
import AddExistingAccountToUser from "./pages/admin/accounts/add-existing-account-to-user"
import AdminGroups from "./pages/admin/groups"
import AdminAccountTypes from "./pages/admin/account-types"
import MyProfile from "./pages/master/MyProfile"
import AccountReports from "./pages/admin/account-reports"
import TransferCredit from "./pages/admin/credit-management/transfer-credit"
import CreditHistory from "./pages/admin/credit-management/credit-history"
import WalletRequestHistory from "./pages/admin/wallet-requests/wallet-requests-history"
import WalletTransfer from "./pages/admin/wallet-requests/wallet-transfer"
import UserWallet from "./pages/master/wallet"
import AdminEditUser from "./pages/admin/edit-user"
import AdminNotifications from "./pages/admin/notifications"
import AdminLogger from "./pages/admin/logger"
const MyTransactions = lazy(() => import("./pages/master/MyTransactions"))
const Transfer = lazy(() => import("./pages/master/Transfer"))
const Deposists = lazy(() => import("./pages/master/Deposits"))
const Withdrawal = lazy(() => import("./pages/master/Withdrawal"))
const Dashboard = lazy(() => import("./pages/admin/Dashboard"))
const Clients = lazy(() => import("./pages/admin/clients"))
const IBApproved = lazy(() => import("./pages/admin/IBApproved"))
const Payments = lazy(() => import("./pages/admin/payments"))
const CreateAccounts = lazy(() => import("./pages/master/CreateAccounts"))
const Support = lazy(() => import("./pages/master/Support"))
const SupportOneView = lazy(() => import("./pages/master/SupportOneView"))
const KYC = lazy(() => import("./pages/master/KYC"))
const IBDashboard = lazy(() => import("./pages/ib/Dashboard"))
const IBWithdraw = lazy(() => import("./pages/ib/Withdraw"))
const IBSummary = lazy(() => import("./pages/ib/Summary"))
const IBStatistics = lazy(() => import("./pages/ib/Statistics"))
const IBTransactions = lazy(() => import("./pages/ib/Transactions"))
const IBTradeReports = lazy(() => import("./pages/ib/TradeReports"))
const IBDepositReportPage = lazy(() => import("./pages/ib/IBDepositReportPage"))
const IBWithdrawalReportPage = lazy(() =>
  import("./pages/ib/IBWithdrawalReportPage")
)

const IBWithdrawalListReportPage = lazy(() =>
  import("./pages/ib/ListReports/IBWithdrawalListReportPage")
)

const IBDepositListReportPage = lazy(() =>
  import("./pages/ib/ListReports/IBDepositListReportPage")
)

const IBTradesCountPage = lazy(() => import("./pages/ib/IBTradesCount"))
const IBLiveAcReports = lazy(() => import("./pages/ib/LiveAcReports"))
const IBComissionReports = lazy(() => import("./pages/ib/CommissionReports"))
const IBKYCPage = lazy(() => import("./pages/ib/KYCReport"))

const Accounts = lazy(() => import("./pages/master/Accounts/Index"))
const MyKYC = lazy(() => import("./pages/master/MyKYC"))
const IBTree = lazy(() => import("./pages/ib/IBTree"))
const ResetPasswordPage = lazy(() => import("./pages/master/ResetPassword"))
const AdminDeposits = lazy(() => import("./pages/admin/deposits"))
const AdminWithdraw = lazy(() => import("./pages/admin/withdraw"))
const AdminTransactions = lazy(() => import("./pages/admin/transactions"))
const AdminUsers = lazy(() => import("./pages/admin/users"))
const AdminUserAccounts = lazy(() => import("./pages/admin/accounts"))
const AdminKYCRecords = lazy(() => import("./pages/admin/kyc"))
const AdminInternalTransfer = lazy(() =>
  import("./pages/admin/internal-transfer")
)
const AdminIBList = lazy(() => import("./pages/admin/ib"))
const AdminIBRequests = lazy(() => import("./pages/admin/ib-requests"))
const AdminIBPlans = lazy(() => import("./pages/admin/ib-plans"))

const Navigation = () => {
  return (
    <>
      <Suspense fallback={<FullPageLoader />}>
        <Routes>
          {/* client pages */}
          <Route
            path="/dashboard"
            element={
              <WithoutAdmin>
                <Ecommerce />
              </WithoutAdmin>
            }
          />
          <Route
            path="/my-profile"
            element={
              <WithoutAdmin>
                <MyProfile />
              </WithoutAdmin>
            }
          />
          <Route
            path="/my-trades"
            element={
              <WithoutAdmin>
                <MyTrades />
              </WithoutAdmin>
            }
          />
          <Route
            path="/downloads"
            element={
              <WithoutAdmin>
                <MyDownloads />
              </WithoutAdmin>
            }
          />
          <Route
            path="/accounts"
            element={
              <WithoutAdmin>
                <Accounts />
              </WithoutAdmin>
            }
          />
          <Route
            path="/accounts/create"
            element={
              <WithoutAdmin>
                <CreateAccounts />
              </WithoutAdmin>
            }
          />
          <Route
            path="/transfer"
            element={
              <WithoutAdmin>
                <Transfer />
              </WithoutAdmin>
            }
          />
          <Route
            path="/my-transactions"
            element={
              <WithoutAdmin>
                <MyTransactions />
              </WithoutAdmin>
            }
          />
          <Route
            path="/my-account"
            element={
              <WithoutAdmin>
                <MyAccount />
              </WithoutAdmin>
            }
          />
          <Route
            path="/deposits"
            element={
              <WithoutAdmin>
                <Deposists />
              </WithoutAdmin>
            }
          />
          <Route
            path="/withdraw"
            element={
              <WithoutAdmin>
                <Withdrawal />
              </WithoutAdmin>
            }
          />
          <Route
            path="/kyc"
            element={
              <WithoutAdmin>
                <KYC />
              </WithoutAdmin>
            }
          />
          <Route
            path="/my-documents"
            element={
              <WithoutAdmin>
                <MyKYC />
              </WithoutAdmin>
            }
          />
          <Route
            path="/notifications"
            element={
              <WithoutAdmin>
                <AdminNotifications />
              </WithoutAdmin>
            }
          />
          <Route
            path="/my-documents/upload"
            element={
              <WithoutAdmin>
                <KYC />
              </WithoutAdmin>
            }
          />
          <Route
            path="/open-trades"
            element={
              <WithoutAdmin>
                <OpenTrades />
              </WithoutAdmin>
            }
          />
          <Route
            path="/support"
            element={
              <WithoutAdmin>
                <Support />
              </WithoutAdmin>
            }
          />
          <Route
            path="/support/:id"
            element={
              <WithoutAdmin>
                <SupportOneView />
              </WithoutAdmin>
            }
          />
          <Route
            path="/ai-terms-and-conditions"
            element={
              <WithoutAdmin>
                <AITermsAndConditions />
              </WithoutAdmin>
            }
          />
          <Route
            path="/wallet"
            element={
              <WithoutAdmin>
                <UserWallet />
              </WithoutAdmin>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          {/* admin pages */}
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route
            path="/admin/create-user"
            element={
              <WithAdmin>
                <AdminAddNewUser />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/users/:id/edit"
            element={
              <WithAdmin>
                <AdminEditUser />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/user-deposit"
            element={
              <WithAdmin>
                <AdminDepositWithDrawalRequest type="Deposit" />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/user-withdraw"
            element={
              <WithAdmin>
                <AdminDepositWithDrawalRequest type="Withdraw" />
              </WithAdmin>
            }
          />

          <Route
            path="/admin/notifications"
            element={
              <WithAdmin>
                <AdminNotifications />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/logs"
            element={
              <WithAdmin>
                <AdminLogger />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/create-internal-transfer"
            element={
              <WithAdmin>
                <AdminAddInternalTransferRequest />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/create-withdraw-ib-commission"
            element={
              <WithAdmin>
                <AdminWithdrawIBCommissionRequest />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/deposit-ib-commission"
            element={
              <WithAdmin>
                <AdminDepositIBCommission />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/open-trading-account"
            element={
              <WithAdmin>
                <AdminAddTradingAccount />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/clients"
            element={
              <WithAdmin>
                <Clients />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/kyc"
            element={
              <WithAdmin>
                <AdminKYCRecords />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/manage-roles"
            element={
              <WithAdmin>
                <AdminManageRoles />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/ib-approved"
            element={
              <WithAdmin>
                <IBApproved />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/payments"
            element={
              <WithAdmin>
                <Payments />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/deposits"
            element={
              <WithAdmin>
                <AdminDeposits />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/withdraw"
            element={
              <WithAdmin>
                <AdminWithdraw />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/transactions"
            element={
              <WithAdmin>
                <AdminTransactions />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/users"
            element={
              <WithAdmin>
                <AdminUsers />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/approved-ibs"
            element={
              <WithAdmin>
                <AdminIBList />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/ib-requests"
            element={
              <WithAdmin>
                <AdminIBRequests />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/accounts"
            element={
              <WithAdmin>
                <AdminUserAccounts />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/internal-transfer"
            element={
              <WithAdmin>
                <AdminInternalTransfer />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/credit-in-out"
            element={
              <WithAdmin>
                <TransferCredit />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/credit-history"
            element={
              <WithAdmin>
                <CreditHistory />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/ib-plans"
            element={
              <WithAdmin>
                <AdminIBPlans />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/trades"
            element={
              <WithAdmin>
                <IBTrades />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/account-reports"
            element={
              <WithAdmin>
                <AccountReports />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/settings"
            element={
              <WithAdmin>
                <AdminSettings />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/support"
            element={
              <WithAdmin>
                <AdminSupport />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/support/:id"
            element={
              <WithAdmin>
                <AdminSupportOneView />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/ib-withdraw-requests"
            element={
              <WithAdmin>
                <AdminIBWithdrawRequests />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/add-existing-mt5-account-to-user"
            element={
              <WithAdmin>
                <AddExistingAccountToUser />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/groups"
            element={
              <WithAdmin>
                <AdminGroups />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/account-types"
            element={
              <WithAdmin>
                <AdminAccountTypes />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/wallet/create"
            element={
              <WithAdmin>
                <WalletTransfer />
              </WithAdmin>
            }
          />
          <Route
            path="/admin/wallet"
            element={
              <WithAdmin>
                <WalletRequestHistory />
              </WithAdmin>
            }
          />
          <Route
            path="/admin"
            element={<Navigate to="/admin/deposits" replace />}
          />
          {/* ib Pages */}
          <Route path="/ib/dashboard" element={<IBDashboard />} />
          <Route path="/ib/withdraw" element={<IBWithdraw />} />
          <Route path="/ib/summary" element={<IBSummary />} />
          <Route path="/ib/statistics" element={<IBStatistics />} />
          <Route path="/ib/transactions" element={<IBTransactions />} />
          <Route path="/ib/trade-reports" element={<IBTradesCountPage />} />
          <Route path="/ib/live-ac-reports" element={<IBLiveAcReports />} />
          <Route path="/ib/kyc" element={<IBKYCPage />} />
          <Route
            path="/ib/commission-reports"
            element={<IBComissionReports />}
          />
          <Route path="/ib/deposit-reports" element={<IBDepositReportPage />} />
          <Route
            path="/ib/withdrawal-reports"
            element={<IBWithdrawalReportPage />}
          />
          <Route
            path="/ib/detailed-withdrawal-reports"
            element={<IBWithdrawalListReportPage />}
          />
          <Route
            path="/ib/detailed-deposit-reports"
            element={<IBDepositListReportPage />}
          />
          <Route path="/ib/my-tree" element={<IBTree />} />
          <Route path="/ib/trades" element={<IBTrades />} />
          <Route path="/ib" element={<Navigate to="/ib/dashboard" replace />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </>
  )
}

export default Navigation
