import { isEmpty } from "lodash"
import { createContext, useState, useEffect } from "react"
import { getMyIBRequestService } from "../services/ib"
import useSession from "../hooks/useSession"

export const IBContext = createContext()

export const IBContextConsumer = IBContext.Consumer

export const IB_STATUS_CONS = {
  IB: "IB",
  NOT_IB: "NOT_IB",
  REQUEST_PENDING: "REQUEST_PENDING",
}

export const IBContextProvider = ({ children }) => {
  const [user, isUserLoading] = useSession()
  const [status, setIBStatus] = useState(null)

  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    if (!isEmpty(user)) {
      if (user?.type === "IB") {
        setIBStatus(IB_STATUS_CONS.IB)
      }

      setIsFetching(false)
    }
  }, [user, isUserLoading])

  return (
    <IBContext.Provider
      value={{
        status,
        isFetching,
        setIBStatus,
      }}
    >
      {children}
    </IBContext.Provider>
  )
}
