import { isEmpty } from "lodash"
import useSession from "../hooks/useSession"
import { FullPageLoader } from "../components/loader"
import { useEffect } from "react"
import adminData from "../data/master/admin-sidebar.json"
import _ from "lodash"

export const WithAdmin = ({ children }) => {
  const [user, isLoading] = useSession()

  useEffect(() => {
    if (!isLoading) {
      if (isEmpty(user) || user?.type != "ADMIN") {
        window.location.href = "/"
      }
    }
  }, [user, isLoading])

  if (isLoading || isEmpty(user) || user?.type != "ADMIN")
    return <FullPageLoader />

  if (hasPermission(user)) {
    window.location.href = "/"
  }

  return <> {children} </>
}

const hasPermission = (user) => {
  return false
}
