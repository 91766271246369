import { useState, useEffect } from "react";

function useTimeAgo(createdTimestamp) {
  const [duration, setDuration] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    const calculateTimeAgo = (timestamp) => {
      const now = new Date();
      const createdDate = new Date(timestamp);

      const timeDiff = now - createdDate;

      const minute = 60 * 1000;
      const hour = 60 * minute;
      const day = 24 * hour;
      const month = 30 * day;

      const timeString = createdDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      setTime(timeString);

      if (timeDiff < minute) {
        const secondsAgo = Math.floor(timeDiff / 1000);
        return `${secondsAgo === 1 ? "1 second" : `${secondsAgo} seconds`} ago`;
      } else if (timeDiff < hour) {
        const minutesAgo = Math.floor(timeDiff / minute);
        return `${minutesAgo === 1 ? "1 minute" : `${minutesAgo} minutes`} ago`;
      } else if (timeDiff < day) {
        const hoursAgo = Math.floor(timeDiff / hour);
        return `${hoursAgo === 1 ? "1 hour" : `${hoursAgo} hours`} ago`;
      } else if (timeDiff < month) {
        const daysAgo = Math.floor(timeDiff / day);
        return `${daysAgo === 1 ? "1 day" : `${daysAgo} days`} ago`;
      } else {
        const monthsAgo = Math.floor(timeDiff / month);
        return `${monthsAgo === 1 ? "1 month" : `${monthsAgo} months`} ago`;
      }
    };

    const updateRelativeTime = () => {
      setDuration(calculateTimeAgo(createdTimestamp));
    };

    if (createdTimestamp) updateRelativeTime();

    // Update the relative time every minute
    const intervalId = setInterval(updateRelativeTime, 60 * 1000);

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [createdTimestamp]);

  return { duration, time };
}

function useTimeAgoGrouped(dataArray) {
  const [groupedData, setGroupedData] = useState({});

  useEffect(() => {
    const groupDataByTime = (data) => {
      const groups = {};

      data.forEach((item) => {
        const createdDate = new Date(item.created_at);
        const timeString = createdDate.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }); // Use "hour:minute AM/PM" format

        const dateString = createdDate.toLocaleDateString("en-GB"); // Use "day/month/year" format

        if (!groups[dateString]) {
          groups[dateString] = [];
        }

        groups[dateString].push({
          ...item,
          time: timeString, // Add a new key 'time' with formatted time
        });
      });

      // Convert the grouped object into an array
      const resultArray = Object.keys(groups).map((dateString) => ({
        date: dateString,
        items: groups[dateString],
      }));

      return resultArray;
    };

    const updateRelativeTime = () => {
      setGroupedData(groupDataByTime(dataArray));
    };

    if (dataArray) updateRelativeTime();
  }, [dataArray]);

  return groupedData;
}

export { useTimeAgoGrouped, useTimeAgo };
