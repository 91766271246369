import React, { useState } from "react"
import { Row, Col } from "react-bootstrap"
import { Box, Button, Item } from "../../components/elements"
import { CardLayout, CardHeader } from "../../components/cards"
import { Breadcrumb, Pagination } from "../../components"
import PageLayout from "../../layouts/PageLayout"
import useSession from "../../hooks/useSession"
import { Skeleton, Grid } from "@chakra-ui/react"
import { useQuery } from "react-query"
import { getMyAccounts, getTradesService } from "../../services/client"
import _ from "lodash"
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "../../components/elements/Table"
import convertDateToSeconds from "../../utils/convertSecondsToDate"
import { LabelField } from "../../components/fields"

export default function AITermsAndConditions() {
  const [user] = useSession()

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <br />
          <CardLayout>
            <Breadcrumb title="AITermsAndConditions">
              <Item className="mc-breadcrumb-item">Home</Item>
              <Item className="mc-breadcrumb-item">AITermsAndConditions</Item>
            </Breadcrumb>
          </CardLayout>
        </Col>
      </Row>
      <br />
      {user ? <TransactionsContainer user={user} /> : null}
    </PageLayout>
  )
}

const TransactionsLoader = () => {
  return <Skeleton minHeight="500px" />
}

const TransactionsContainer = ({ user }) => {
  return (
    <Row>
      <Col xl={12}>
        <CardLayout>
          <CardHeader title="AI TermsAndConditions" />
          <br />

          <Grid gap={6}>
            <Box>
              <b>Reliability Disclaimer:</b>
              The AI trading system operates solely on algorithms without human
              intervention, making it susceptible to potential fluctuations in
              generating profits on individual trades. Hence, its reliability
              cannot be fully guaranteed.
            </Box>
            <Box>
              <b> Acknowledgment of Risks:</b> Users must acknowledge the
              inherent risks associated with AI trading and accept the outcomes,
              regardless of the results obtained.
            </Box>
            <Box>
              <b>Manual Trade Responsibility:</b> Should a client choose to
              engage in manual trading, the AI trading system will not be held
              accountable for any resulting profits or losses. The client will
              bear full responsibility in such instances.
            </Box>
            <Box>
              <b>Minimum Deposit Requirement:</b> A minimum deposit of $5000 is
              mandatory to access the AI trading system. Deposit above $5000
              must be in multiple of 5000 only
            </Box>
            <Box>
              <b>Withdrawal Protocol:</b> During the initial 3-month period, no
              withdrawals are permitted. Subsequently, clients must request
              withdrawals through the CRM. Open trades will be closed at the
              time of withdrawal, regardless of their profit or loss status.
            </Box>
            <Box>
              <b>Profit Withdrawal Process:</b>
              Profit withdrawals are feasible only at the end of each month and
              can be requested between the 1st and 5th of the month through the
              CRM. All withdrawals will be processed exclusively in USDT.
            </Box>
            <Box>
              <b>Unauthorized Activity:</b>
              Any unauthorized activities, including copy trading, discovered on
              a client's account, will result in immediate termination of the
              account. All ongoing trades, irrespective of their financial
              status, will be closed. The client will be held solely accountable
              for any such activities.
            </Box>
            <Box>
              <b>Liability Limitation:</b> Orbit Global Ltd shall not be held
              responsible for any profits or losses incurred through the AI
              trading system. Orbit Global Ltd solely functions as the platform
              service provider. By proceeding with the use of the AI trading
              system, I acknowledge and agree to all the terms and conditions
              specified above and by providing the aforementioned details in
              this email, I confirm my full understanding and acceptance of all
              the terms and conditions associated with the AI trading system.
            </Box>

            <Box>
              <b>Account Deactivation:</b> <br />
              <b>Client Request:</b> You can deactivate the AI trading system on
              your account by sending a confirmation email requesting its
              termination. <br />
              <b>Deactivation Processing:</b> We will deactivate the AI system
              within 24 business hours of receiving your confirmation email.
              <br />
              <b>Open Trade Closure (Default)</b> By default, all open trades
              will be closed upon deactivation, regardless of their profit or
              loss status. <br />
              <b>Keeping Trades Open:</b> If you wish to keep open trades active
              during deactivation, you must explicitly state this in your
              confirmation email. <br />
              <b>Default Deactivation Process:</b> In the absence of any
              instructions regarding open trades, the AI system will be
              deactivated by closing all trades.
              <br />
            </Box>
          </Grid>
        </CardLayout>
      </Col>
    </Row>
  )
}

const thead = [
  "Login ID",
  "Volume",
  "Symbol",
  "Open Price",
  "Stop Loss",
  "Take Profit",
  "Position ID",
  "Deal",
  "Order",
  "Action",
  "Entry",
  "Comment",
  "Profit",
  "Time",
]

const MyTradesTable = ({ trades }) => {
  return (
    <>
      <Box className="mc-table-responsive">
        <Table className="mc-table product">
          <Thead className="mc-table-head primary">
            <Tr>
              {thead.map((item, index) => (
                <Th key={index}>{item}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody className="mc-table-body even">
            {trades?.length ? (
              <>
                {_.map(trades, (_r) => {
                  return (
                    <Tr key={_r?.id}>
                      <Td>{_r?.loginId}</Td>

                      <Td>{_r?.volume ? parseInt(_r?.volume) / 10000 : "-"}</Td>
                      <Td>{_r?.symbol}</Td>
                      <Td>{_r?.price}</Td>
                      <Td>{_r?.sl ?? 0}</Td>
                      <Td>{_r?.tp ?? 0}</Td>

                      <Td>{_r?.positionID}</Td>
                      <Td>{_r?.deal}</Td>
                      <Td>{_r?.order_id}</Td>
                      <Td>{_r?.action == 0 ? "Buy" : "Sell"}</Td>
                      <Td>Close</Td>

                      <Td>{_r?.comment}</Td>
                      <Td>{_r?.profit}</Td>
                      <Td>{convertDateToSeconds(_r?.time)}</Td>
                    </Tr>
                  )
                })}
              </>
            ) : (
              <Tr>
                <Td colSpan={thead?.length ?? 1}>
                  <div style={{ textAlign: "center", width: "100%" }}>
                    No Data found
                  </div>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  )
}
