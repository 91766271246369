import api from "./api"

export const registerService = (
  name = "",
  email = "",
  password = "",
  country = "",
  ib = "",
  phone = "",
  address = ""
) => {
  let params = {
    name: name,
    email: email,
    password: password,
    country: country,
  }

  if (ib) {
    params.ib = ib
  }

  if (phone) {
    params.phone = phone
  }

  if (address) {
    params.address = address
  }

  return api.post("/v1/registration", params)
}

export const loginService = (email = "", password = "") => {
  return api.post("/v1/login", {
    email: email,
    password: password,
  })
}

export const f2aLoginService = (email = "", password = "") => {
  return api.post("/v1/authentication", {
    email: email,
    password: password,
  })
}

export const verifyOTPService = (email = "", otp = "") => {
  return api.post("/v1/verify", {
    email: email,
    otp: otp,
  })
}

export const meService = () => {
  return api.get("/v1/me")
}

export const sendForgotPasswordEmailService = (email) => {
  return api.post("/v1/forget-password", { email: email })
}

export const sendResetPasswordEmailService = (
  token,
  password,
  repeat_password
) => {
  return api.post("/v1/reset-password", {
    token: token,
    password: password,
    repeat_password: repeat_password,
  })
}
