import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Tab, Tabs, Form } from "react-bootstrap"
import { Breadcrumb, Pagination } from "../../../components"
import PageLayout from "../../../layouts/PageLayout"
import { Box, Item, Anchor, Button } from "../../../components/elements"
import { CardLayout } from "../../../components/cards"

import {
  Skeleton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Flex,
  Spinner,
  Input,
  useDisclosure,
} from "@chakra-ui/react"
import { LabelField } from "../../../components/fields"
import DepositTable from "./table"
import { useQuery } from "react-query"
import {
  approveRejectRequestService,
  deleteGroup,
  getAllGroups,
  getRequestsByType,
  syncAllGroups,
} from "../../../services/admin.service"
import { useShowToast } from "../../../hooks"
import SearchUser from "../../../components/admin/SearchUser"
import _ from "lodash"
import { formatDateHelper } from "../../../utils/formatDate"
import downloadFile from "../../../utils/downloadFile"
import convertJsonToCsv from "../../../utils/convertJsonToCsv"
import { getSearchParams } from "../../../utils"
import CreateGroupModal from "./CreateGroupModal"

export default function AdminGroups() {
  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <Box className="mc-card">
            <Breadcrumb title="Groups">
              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link">Home</Anchor>
              </Item>

              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link">Groups</Anchor>
              </Item>
            </Breadcrumb>
          </Box>
        </Col>

        <DepositsPaymentPage />
      </Row>
    </PageLayout>
  )
}

const Loader = () => {
  return <Skeleton minHeight="500px" />
}

const DepositsPaymentPage = () => {
  const [currentPage, setCurrentPage] = useState(null)
  const [modifyRequest, setModifyRequest] = useState(null)
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)
  const showToast = useShowToast()
  const [selectedUser, setSelectedUser] = useState(undefined)
  const [forceSearch, setForceSearch] = useState(0.01)
  const [name, setName] = useState("")

  const syncGroupsModalDisclosure = useDisclosure()
  const createGroupModalDisclosure = useDisclosure()

  const { isLoading, error, data, refetch } = useQuery(
    ["admin-all-groups", forceSearch, currentPage],
    () => getAllGroups()
  )

  const total = data?.data?.data?.groups?.total
  const requests = data?.data?.data?.groups?.data ?? []
  const last_page = data?.data?.data?.groups?.last_page ?? 1

  return (
    <>
      <Col xl={12}>
        <CardLayout>
          <Row>
            <Col xl={3}></Col>
            <Col xl={3}></Col>
            <Col xl={3}></Col>

            <Col xl={3}>
              <div
                style={{
                  display: "flex",
                  marginTop: "24px",
                  textAlign: "right",
                }}
              >
                <div style={{ marginLeft: "auto" }}> </div>
                <Button
                  onClick={() => {
                    syncGroupsModalDisclosure.onOpen()
                  }}
                  className="mc-btn green"
                >
                  Sync groups
                </Button>
                &nbsp; &nbsp;
                <Button
                  onClick={() => {
                    createGroupModalDisclosure.onOpen()
                  }}
                  className="mc-btn primary"
                >
                  Create Group
                </Button>{" "}
                &nbsp;
              </div>
            </Col>
          </Row>

          <br />

          {isLoading ? (
            <Loader />
          ) : (
            <>
              <DepositTable
                requests={requests ?? []}
                onChangeStatus={(_val) => {
                  setModifyRequest(_val)
                }}
              />
              <br />
              <Pagination
                currentPage={currentPage}
                lastPage={last_page}
                setCurrentPage={setCurrentPage}
              />
            </>
          )}
        </CardLayout>
      </Col>
      <ModifyRequestAlert
        request={modifyRequest}
        setRequest={setModifyRequest}
        isUpdatingStatus={isUpdatingStatus}
        // onRequestChange={(_c) => handleChangeStatus(_c)}
        refetch={refetch}
      />

      <ConfirmSyncAlert
        isOpen={syncGroupsModalDisclosure?.isOpen}
        onClose={syncGroupsModalDisclosure.onClose}
        refetch={refetch}
      />

      <CreateGroupModal
        isOpen={createGroupModalDisclosure.isOpen}
        onClose={createGroupModalDisclosure?.onClose}
        refetch={refetch}
      />
    </>
  )
}

const ModifyRequestAlert = ({
  request,
  setRequest,
  isUpdatingStatus,
  onRequestChange,
  refetch,
}) => {
  const cancelRef = useRef()
  const [comment, setComment] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const showToast = useShowToast()

  useEffect(() => {
    if (!request) {
      setComment("")
    }
  }, [request])

  const handleDeleteGroup = async () => {
    setIsLoading(true)

    try {
      const result = await deleteGroup({ id: request?.id })

      if (result?.data?.success) {
        await refetch()
        showToast("Success", "Group deleted successfully")
        setRequest(null)
      } else {
        showToast("Error", "Something went wrong", "error")
      }
    } catch (error) {}

    setIsLoading(false)
  }

  return (
    <AlertDialog
      isOpen={request ? true : false}
      leastDestructiveRef={cancelRef}
      onClose={() => {
        setRequest(null)
      }}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete {request?.name} group ?
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure, You want to delete {request?.name} group, the group
            will only be deleted from crm and not MT5 terminal?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Flex>
              <div style={{ marginRight: "4px" }}>
                {!isUpdatingStatus ? (
                  <button
                    onClick={() => {
                      setRequest(null)
                    }}
                    className="mc-btn gray"
                    style={{ textTransform: "uppercase", minHeight: "52px" }}
                  >
                    Cancel
                  </button>
                ) : (
                  <></>
                )}
              </div>

              <div style={{ marginRight: "4px" }}>
                <button
                  onClick={() => {
                    handleDeleteGroup()
                  }}
                  className="mc-btn red"
                  style={{ textTransform: "uppercase", minHeight: "52px" }}
                >
                  {isLoading ? <Spinner /> : "Delete"}
                </button>
              </div>
              <br />
              <br />
            </Flex>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

const ConfirmSyncAlert = ({ isOpen, onClose, refetch }) => {
  const cancelRef = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const showToast = useShowToast()

  const handleSyncGroup = async () => {
    setIsLoading(true)
    try {
      const result = await syncAllGroups()

      if (result?.data?.success) {
        await refetch()
        showToast("Success", "Groups synced successfully")
        onClose()
      } else {
        showToast(
          "Error",
          result?.data?.message ??
            "Something went wrong, please try again later",
          "error"
        )
      }
    } catch (error) {}

    setIsLoading(false)
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Are you sure, you want to sync groups from MT5?
          </AlertDialogHeader>

          <AlertDialogBody>
            All new groups from MT5 will be fetched and added to the crm.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Flex>
              <div style={{ marginRight: "4px" }}>
                <button
                  onClick={() => {
                    if (isLoading) return

                    onClose()
                  }}
                  className="mc-btn gray"
                  style={{ textTransform: "uppercase", minHeight: "52px" }}
                >
                  Cancel
                </button>
              </div>

              <div style={{ marginRight: "4px" }}>
                <button
                  onClick={() => {
                    handleSyncGroup()
                  }}
                  className="mc-btn red"
                  style={{ textTransform: "uppercase", minHeight: "52px" }}
                >
                  {isLoading ? <Spinner /> : "Sync"}
                </button>
              </div>
              <br />
              <br />
            </Flex>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
