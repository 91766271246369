import { useEffect } from "react"
import { STORAGE } from "../../config/storage"
import { redirectToLogin } from "../../utils"
import { FullPageLoader } from "../../components/loader"
import { QueryClient } from "react-query"
import { queryClient } from "../../App"

export const Logout = () => {
  useEffect(() => {
    queryClient.invalidateQueries()
    localStorage.removeItem(STORAGE.USER)
    redirectToLogin()
  }, [])

  return <FullPageLoader />
}
