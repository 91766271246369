// IB, NOT_IB, REQUEST_PENDING

import { useContext, useEffect, useState } from "react"
import useSession from "./useSession"
import { IBContext } from "../context/IBContext"

export const useIB = () => {
  const ctx = useContext(IBContext)

  return [ctx.status, ctx.isFetching, ctx.setIBStatus]
}
