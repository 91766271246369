import React, { useState } from "react"
import {
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
} from "../../../components/elements/Table"
import {
  Anchor,
  Heading,
  Box,
  Text,
  Input,
  Image,
  Icon,
  Button,
} from "../../../components/elements"
import _ from "lodash"
import {
  Avatar,
  Flex,
  Grid,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react"
import { LabelField } from "../../../components/fields"
import { SETTINGS_TABS } from "."

const thead = ["Title", "Value"]
// {req: {}, status}
const DepositTable = ({
  requests,
  onUpdateStatus,
  onChangeSettingValue,
  settingToUpdate,
  isUpdateSetting,
}) => {
  return (
    <>
      <Tabs variant="enclosed">
        <TabList>
          {_.map(SETTINGS_TABS, (_tab) => (
            <Tab key={_tab?.tab} isDisabled={isUpdateSetting}>
              <div style={{ padding: "8px" }}> {_tab?.tab} </div>
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {_.map(SETTINGS_TABS, (_tab) => {
            const settingsRequests = _.filter(requests, (_r) =>
              _tab?.keys?.includes(_r?._tag) ? true : false
            )

            return (
              <TabPanel key={_tab?.tab}>
                <Box className="mc-table-responsive">
                  <Table className="mc-table product">
                    <Thead className="mc-table-head primary">
                      <Tr>
                        {thead.map((item, index) => (
                          <Th key={index}>{item}</Th>
                        ))}
                      </Tr>
                    </Thead>
                    <Tbody className="mc-table-body even">
                      {requests?.length ? (
                        <>
                          {_.map(settingsRequests, (_r) => {
                            return (
                              <Tr key={_r?.id}>
                                <Td>{formatName(_r?._tag)} </Td>

                                <Td>
                                  <LabelField
                                    name={_r?._tag}
                                    type="text"
                                    value={_r?._value}
                                    onChange={onChangeSettingValue}
                                  />
                                </Td>
                              </Tr>
                            )
                          })}

                          <Tr>
                            <Td colSpan={2}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  minWidth: "120px",
                                }}
                              >
                                {!settingToUpdate ? (
                                  <button
                                    onClick={() => {
                                      onUpdateStatus(settingsRequests)
                                    }}
                                    className="mc-btn primary sm mr-2 ml-auto"
                                  >
                                    <div style={{ padding: "8px" }}>
                                      {" "}
                                      Update{" "}
                                    </div>
                                  </button>
                                ) : (
                                  <>{settingToUpdate ? <Spinner /> : null}</>
                                )}
                              </div>
                            </Td>
                          </Tr>
                        </>
                      ) : (
                        <Tr>
                          <Td colSpan={thead?.length ?? 1}>
                            <div style={{ textAlign: "center", width: "100%" }}>
                              No Data found
                            </div>
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </Box>
              </TabPanel>
            )
          })}

          <TabPanel>
            <p>two!</p>
          </TabPanel>
          <TabPanel>
            <p>three!</p>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

export default DepositTable

const formatName = (_name) => {
  return _name.split("_").join(" ")
}
