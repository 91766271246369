import React, { useState, useEffect } from "react"
import { Modal, Form } from "react-bootstrap"
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table"
import {
  Button,
  Image,
  Input,
  Text,
  Box,
  Icon,
  Anchor,
  Option,
  Heading,
} from "../elements"
import userInfo from "../../data/master/userList.json"
import moment from "moment"
import _, { reverse } from "lodash"
import { TransactionFlowEnum } from "../../config/enum"

export default function TransactionsTable({
  thead,
  tbody,
  data,
  methods,
  accounts,
}) {
  const [userData, setUserData] = React.useState("")
  const [editModal, setEditModal] = React.useState(false)
  const [blockModal, setBlockModal] = React.useState(false)

  const getUserAccountById = (id) => {
    try {
      const acc = _.find(accounts, (_acc) => (id === _acc?.id ? true : false))

      if (acc) return acc?.account_id
    } catch (error) {}
    return id
  }

  const getMethodById = (id) => {
    try {
      const acc = _.find(methods, (_acc) => (id === _acc?.id ? true : false))

      if (acc) return acc?.details
    } catch (error) {}
    return id
  }

  const getTo = (item) => {
    console.log()

    switch (item.type) {
      case "DEPOSIT":
        return getUserAccountById(item?.user_account_id)

      case "TRANSFER":
        return getUserAccountById(item?.reference)

      default:
        return getUserAccountById(item?.user_account_id) ?? "-"
    }
  }

  const getFrom = (item) => {
    switch (item.type) {
      case "DEPOSIT":
        return getUserAccountById(item?.user_account_id)

      case "TRANSFER":
        return (
          getUserAccountById(item?.from_account) +
          " to " +
          getUserAccountById(item?.to_account)
        )

      default:
        return getUserAccountById(item?.user_account_id)
    }
  }

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {data?.map((item, index) => (
            <Tr key={item?.id}>
              <Td title={item.type}>{item.id}</Td>
              <Td title={item.type}>
                {item?.transaction_flow === TransactionFlowEnum?.DIRECT_WALLET
                  ? "WALLET"
                  : item.type}
              </Td>
              <Td title={item.type}>
                {item?.transaction_flow === TransactionFlowEnum?.DIRECT_WALLET
                  ? "WALLET"
                  : getFrom(item)}
              </Td>
              <Td title={item.amount}>{item.amount}</Td>
              <Td>{item.comment ?? "-"}</Td>
              <Td title={item.status}>
                {item.status === "UNVERIFIED" && (
                  <Text className="mc-table-badge red">{item.status}</Text>
                )}
                {item.status === "ACCEPTED" && (
                  <Text className="mc-table-badge green">{item.status}</Text>
                )}
                {item.status === "PENDING" && (
                  <Text className="mc-table-badge purple">{item.status}</Text>
                )}
                {item.status === "REJECTED" && (
                  <Text className="mc-table-badge red">{item.status}</Text>
                )}
              </Td>
              <Td title={item.created_at}>
                {moment(item.created_at).format("Do MMM YYYY, h:mm:ss a")}
              </Td>
              {/* <Td title={getFrom(item)}>{getFrom(item)}</Td>
              <Td title={getTo(item)}>{getTo(item)}</Td>

              <Td title={item.currency}>USDT</Td> */}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal
        show={editModal}
        onHide={() => setEditModal(false, setUserData(""))}
      >
        <Box className="mc-user-modal">
          <Image src={userData.src} alt={userData?.alt} />
          <Heading as="h4">{userData?.name}</Heading>
          <Text as="p">{userData?.email}</Text>
          <Form.Group className="form-group inline mb-4">
            <Form.Label>role</Form.Label>
            <Form.Select>
              <Option>{userData?.role ? userData?.role.text : ""}</Option>
              {userInfo.role.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="form-group inline">
            <Form.Label>status</Form.Label>
            <Form.Select>
              <Option>{userData?.status}</Option>
              {userInfo.status.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Form.Select>
          </Form.Group>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => setEditModal(false)}
            >
              close popup
            </Button>
            <Button
              type="button"
              className="btn btn-success"
              onClick={() => setEditModal(false)}
            >
              save Changes
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>

      <Modal show={blockModal} onHide={() => setBlockModal(false)}>
        <Box className="mc-alert-modal">
          <Icon type="new_releases" />
          <Heading as="h3">are your sure!</Heading>
          <Text as="p">Want to block this user's account?</Text>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => setBlockModal(false)}
            >
              nop, close
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => setBlockModal(false)}
            >
              yes, block
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>
    </Box>
  )
}
