import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Grid,
  useToast,
  Spinner,
} from "@chakra-ui/react"
import { Button } from "../../../components/elements"
import * as Yup from "yup"
import { useFormik } from "formik"
import { LabelField } from "../../../components/fields"
import {
  createAdminUserService,
  getAllUsersServices,
} from "../../../services/admin.service"
import _ from "lodash"
import { useShowToast } from "../../../hooks"

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  name: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Name is required"),
  password: Yup.string().min(6, "Too Short!").required("Required"),
})

const AddUserModal = ({ isOpen, onOpen, onClose, refetch }) => {
  const toast = useShowToast()

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: SignupSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true)

      try {
        const users = await getAllUsersServices()

        const userExists = _.find(users?.data?.data?.users?.data, (_u) => {
          return _u?.email === values?.email ? true : false
        })

        if (userExists) {
          actions.setFieldError("email", "Email address already registered.")
          actions.setSubmitting(false)
          return
        }

        const result = await createAdminUserService(values)

        if (result?.data?.success) {
          await refetch()
          toast("Success", "User added successfully")
          actions.resetForm()
          onClose()

          return
        }
      } catch (error) {}

      toast("Error", "Error in adding user", "error")
      actions.setSubmitting(false)
      //handleRegister(values, actions)
    },
  })

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="xl"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create User</ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody>
            <form onSubmit={formik.handleSubmit}>
              <Grid gap={4}>
                <LabelField
                  label="Name"
                  type="text"
                  name="name"
                  value={formik?.values?.name}
                  onBlur={formik?.handleBlur}
                  onChange={formik?.handleChange}
                  errorMessage={
                    formik?.errors?.name && formik?.touched?.name
                      ? formik?.errors?.name
                      : false
                  }
                />

                <LabelField
                  size="sm"
                  label="Email"
                  type="text"
                  name="email"
                  value={formik?.values?.email}
                  onBlur={formik?.handleBlur}
                  onChange={formik?.handleChange}
                  errorMessage={
                    formik?.errors?.email && formik?.touched?.email
                      ? formik?.errors?.email
                      : false
                  }
                />

                <LabelField
                  label="password"
                  type="text"
                  name="password"
                  value={formik?.values?.password}
                  onBlur={formik?.handleBlur}
                  onChange={formik?.handleChange}
                  errorMessage={
                    formik?.errors?.password && formik?.touched?.password
                      ? formik?.errors?.password
                      : false
                  }
                />

                <br />

                <Flex justify="space-between" align="center">
                  <Button
                    variant="ghost"
                    onClick={onClose}
                    disabled={formik?.isSubmitting}
                  >
                    Close
                  </Button>{" "}
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <Button
                    type="submit"
                    className="mc-btn primary"
                    mr={3}
                    disabled={formik?.isSubmitting}
                  >
                    {formik?.isSubmitting ? <Spinner /> : "Create User"}
                  </Button>
                </Flex>
              </Grid>
            </form>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddUserModal
