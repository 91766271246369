import { useState, useEffect } from "react";

const usePriority = (value) => {
  const [priority, setPriority] = useState({});

  useEffect(() => {
    switch (value.toLowerCase()) {
      case "low":
        setPriority({ color: "#5B5D5D", value: "Low" });
        break;
      case "medium":
        setPriority({ color: "#ffbf01", value: "Medium" });
        break;
      case "high":
        setPriority({ color: "#F3502B", value: "Important" });
        break;
      default:
        setPriority({ color: "black", value: value });
    }
  }, [value]);

  return priority;
};

const useStatus = (value) => {
  const [status, setStatus] = useState({});

  useEffect(() => {
    switch (value.toLowerCase()) {
      case "open":
        setStatus({ color: "#5B5D5D", value: "OPEN" });
        break;
      case "pending":
        setStatus({ color: "#4861EF", value: "ON PROGRESS" });
        break;
      case "resolved":
        setStatus({ color: "#3CF033", value: "COMPLETED" });
        break;
      default:
        setStatus({ color: "black", value: value });
    }
  }, [value]);

  return status;
};

export { usePriority, useStatus };
