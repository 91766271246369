import { Col, Row } from "react-bootstrap"
import PageLayout from "../../../../layouts/PageLayout"
import { Box, Item, Anchor, Button } from "../../../../components/elements"
import { Breadcrumb } from "../../../../components"
import { CardLayout } from "../../../../components/cards"
import TransferCreditsForm from "./transfer-credit-from"
import { useQuery } from "react-query"
import { adminGetAllAccounts } from "../../../../services/admin.service"
import { getPaymentMethodsService } from "../../../../services/client"
import { Skeleton } from "@chakra-ui/react"

const WalletTransfer = () => {
  const { isLoading, error, data, refetch } = useQuery(["admin-accounts"], () =>
    adminGetAllAccounts()
  )

  const paymentQuery = useQuery(["admin-payment-methods"], () =>
    //getPaymentMethodsService()
    getPaymentMethodsService()
  )

  const accounts = data?.data?.data?.accounts?.data?.data ?? []

  const methods = paymentQuery?.data?.data?.data?.accounts?.data ?? []
  return (
    <>
      <PageLayout>
        <Row>
          <Col xl={12}>
            <Box className="mc-card">
              <Breadcrumb title="Add/Remove Wallet Balance">
                <Item className="mc-breadcrumb-item">
                  <Anchor className="mc-breadcrumb-link" href="/admin">
                    Home
                  </Anchor>
                </Item>

                <Item className="mc-breadcrumb-item">
                  <Anchor className="mc-breadcrumb-link" href="/admin">
                    Add/Remove Wallet Balance
                  </Anchor>
                </Item>
              </Breadcrumb>
            </Box>
          </Col>
        </Row>

        <br />

        <Col xl={12}>
          <CardLayout>
            {isLoading ? (
              <Loader />
            ) : (
              <TransferCreditsForm accounts={accounts} methods={methods} />
            )}
          </CardLayout>
        </Col>
      </PageLayout>
    </>
  )
}

export default WalletTransfer

const Loader = () => {
  return <Skeleton minHeight="500px" />
}
