import moment from "moment"

export const formatDateHelper = (_date, withoutComma = false) => {
  if (withoutComma) {
    return moment(_date).format("Do MMM YYYY h:mm:ss a")
  }

  return moment(_date).format("Do MMM YYYY, h:mm:ss a")
}

export default formatDateHelper

export const formatDateToYYYYMMDD = (_date) => {
  return moment(_date).format("YYYY-MM-DD")
}
