import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Grid,
  Text,
  Box,
  Spinner,
} from "@chakra-ui/react"
import { useState } from "react"
import { LabelField } from "../../../components/fields"
import { createGroup } from "../../../services/admin.service"
import { useShowToast } from "../../../hooks"

const CreateGroupModal = ({ isOpen, onClose, refetch }) => {
  const [name, setName] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const showToast = useShowToast()

  const handleCreateGroup = async () => {
    setIsLoading(true)

    if (!name?.trim()?.length) {
      setIsLoading(false)
      return
    }

    try {
      const result = await createGroup({ name: name })

      if (result?.data?.success) {
        await refetch()
        showToast("Success", "Group created successfully")
        handleClose()
      } else {
        showToast(
          "Error",
          result?.data?.message ?? "Something went wrong",
          "error"
        )
      }
    } catch (error) {}

    setIsLoading(false)
  }

  const handleClose = () => {
    setName("")
    onClose()
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        isCentered
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box pt={4}>
              <Grid gap={6}>
                <Box>
                  <LabelField
                    label="Group Name"
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setName(e?.target?.value)
                    }}
                  />
                  <Text fontSize="xs">
                    Group will only be created in crm not in MT5
                  </Text>
                </Box>

                <Box textAlign="right">
                  <button className="mc-btn gray">Close</button>
                  &nbsp; &nbsp;
                  <button
                    className="mc-btn primary"
                    onClick={handleCreateGroup}
                  >
                    {isLoading ? <Spinner /> : "Create"}
                  </button>
                </Box>
              </Grid>
            </Box>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CreateGroupModal
